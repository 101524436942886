import React, { Component } from "react";
import { Button, Container } from "react-bootstrap";
import AuthService from "../../../../services/auth-service";
import interventionService from "../../../../services/intervention-service";

class Confirmation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthService.getCurrentUser(),
      content: this.props.data,
      chosenUser: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  saveAndContinue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  handleSubmit(e) {
    e.preventDefault();

    interventionService
      .generateIntervention(
        this.state.content.userIdfrom[0]._id,
        this.state.currentUser.id,
        "6434e00b36a83600143f9016",
        this.makeArray(
          this.props.inputValues.understand,
          this.props.inputValues.collaborate,
          this.props.inputValues.tutoring,
          this.props.inputValues.emotional
        )
      )
      .then(() => {
        window.location.reload();
      });
  }

  makeArray(understand, collaborate, tutoring, emotional) {
    let details = [];

    if (understand !== "") {
      details.push(understand);
    }

    if (collaborate !== "") {
      details.push(collaborate);
    }

    if (tutoring !== "") {
      details.push(tutoring);
    }

    if (emotional !== "") {
      details.push(emotional);
    }

    return details;
  }
  render() {
    const {
      inputValues: { understand, collaborate, tutoring, emotional },
    } = this.props;

    let username = this.state.content.userIdfrom[0].username;

    return (
      <Container>
        <img
          src={
            "https://publichelpdet.s3.us-west-2.amazonaws.com/check-mark.png"
          }
          alt="profile"
          className="profileImage-inter"
        />
        <h3 className="center-text">Confirm your Details</h3>
        <p>Confirm if the following details are correct.</p>
        <p>You are contacting {username} for help!</p>
        <p>
          Your requests include...<br></br>
          <br></br> {understand} {collaborate} {tutoring} {emotional}
        </p>
        <Button variant="secondary" onClick={this.back}>
          Back
        </Button>{" "}
        <Button variant="success" onClick={this.handleSubmit}>
          Submit
        </Button>
      </Container>
    );
  }
}

export default Confirmation;
