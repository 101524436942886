import React, { Component } from "react";
import ELPService from "./../services/elp-service";
import AuthService from "./../services/auth-service";
import RelativeEmotions from "./elp-features-comparision/relative-emotions-component";
import UserEmotionVisual from "./elp-features-comparision/user-emotions-component-visual";
import DeleteEmotionAll from "./elp-features-comparision/delete-emotions-Component";
import moment from "moment";

export default class BoardUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthService.getCurrentUser(),
      content: [],
      imgState: "http://localhost:8080/public/Emotions/",
    };
  }

  componentDidMount() {
    ELPService.getUserEmotions(this.state.currentUser.id).then(
      (response) => {
        this.setState({
          content: response.data.data.emotions,
        });
      },
      (error) => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });
      }
    );
  }

  newDateFormat(rawDate) {
    var stillUtc = moment.utc(rawDate).toDate();

    const fullTimestamp = moment(stillUtc)
      .local()
      .format("MMMM Do YYYY, h:mm:ss a");
    return fullTimestamp;
  }

  render() {
    return (
      <div className="container">
        <h1>Your Emotions</h1>

        <div className="row">
          <div className="col-sm-6">
            <div className="row">
              <div className="col-sm-2 tableEmotions">
                <h4>Your Emotions</h4>{" "}
              </div>
              <div className="col-sm-10 tableEmotions">
                <h4>Collected At</h4>
              </div>
            </div>
            {this.state.content.map((item) => (
              <div className="row">
                <div className="col-sm-4">
                  {item.emotStatus.map((sub) => (
                    <RelativeEmotions id={sub._id}></RelativeEmotions>
                  ))}
                </div>
                <div className="col-sm-4">
                  {this.newDateFormat(item.timestamp)}
                </div>
                <div className="col-sm-2 padding">
                  {/* <DeleteEmotionAll id={item._id}></DeleteEmotionAll> */}
                </div>
              </div>
            ))}
          </div>
          <div className="col-sm-6">
            <h1>Your Peer's Emotions</h1>

            <UserEmotionVisual></UserEmotionVisual>
          </div>
        </div>
      </div>
    );
  }
}
