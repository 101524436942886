import React, { Component } from "react";
import AuthService from "./../../services/auth-service";
import ELPService from "../../services/elp-service";
// import { Chart } from "react-google-charts";
import { Card, Tab, Tabs } from "react-bootstrap";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export default class EmotionDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthService.getCurrentUser(),
      emotionName: "",
      emotionAll: [],
      emotionMonth: [],
      emotionWeek: [],
      countContext: {},
      imgState: "http://localhost:8080/public/UserProfiles/",
    };
  }

  componentDidMount() {
    ELPService.countEmotionsByCount("any").then((response) => {
      const emotionAll = response.data.emotions;

      this.setState({
        emotionAll,
      });
    });

    ELPService.countEmotionsByCount("week").then((response) => {
      const emotionWeek = response.data.emotions;
      this.setState({
        emotionWeek,
      });

      //console.log(emotionWeek);
    });

    ELPService.countEmotionsByCount("month").then((response) => {
      const emotionMonth = response.data.emotions;
      this.setState({
        emotionMonth,
      });
    });
    // ELPService.filterEmotions(this.state.emotionId).then((response) => {
    //   const content = response.data.emotions;
    //   const emotionName = response.data.emotions[0].emotStatus[0].emotion;
    //   console.log(content);
    //   console.log("there");
    //   console.log(emotionName);

    // });
  }

  //   countContext() {
  //     var dict = {};

  //     for (let i = 0; i < this.state.content.length; i++) {
  //       console.log(this.state.content[i].emotContext);
  //       console.log("inside loop");

  //       if (this.state.content[i].emotContext in dict) {
  //         dict[this.state.content[i].emotContext] =
  //           dict[this.state.content[i].emotContext] + 1;
  //       } else {
  //         dict[this.state.content[i].emotContext] = 1;
  //       }
  //     }

  //     console.log(dict);

  //     let array = [];

  //     array.push(["Context", "Count"]);

  //     for (const [key, value] of Object.entries(dict)) {
  //       console.log(key, value);
  //       let arr2 = [];
  //       arr2.push(key);
  //       arr2.push(value);

  //       console.log(arr2);

  //       array.push(arr2);
  //     }

  //     console.log(array);

  //     return array;
  //   }

  render() {
    // console.log(this.state.emotionAll);

    const dataAll = this.state.emotionAll;
    const dataWeek = this.state.emotionWeek;
    const dataMonth = this.state.emotionMonth;

    return (
      <div>
        <Tabs
          defaultActiveKey="current"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="current" title="Current" tabClassName="sub-tab-small">
            <BarChart
              width={500}
              height={300}
              data={dataAll}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="emotionName" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="count" fill="#7ac6f5" />
            </BarChart>
          </Tab>
          <Tab eventKey="week" title="Week" tabClassName="sub-tab-small">
            <BarChart
              width={500}
              height={300}
              data={dataWeek}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="emotionName" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="count" fill="#7ac6f5" />
            </BarChart>
          </Tab>
          <Tab eventKey="month" title="Month" tabClassName="sub-tab-small">
            <BarChart
              width={500}
              height={300}
              data={dataMonth}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="emotionName" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="count" fill="#7ac6f5" />
            </BarChart>
          </Tab>
          <Tab eventKey="all" title="All Time" tabClassName="sub-tab-small">
            <BarChart
              width={500}
              height={300}
              data={dataAll}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="emotionName" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="count" fill="#7ac6f5" />
            </BarChart>
          </Tab>
        </Tabs>
      </div>
    );
  }
}
