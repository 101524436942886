import React, { Component } from "react";
import { Button, Dropdown, ButtonGroup } from "react-bootstrap";
import MessageService from "../services/message-service";
import AuthService from "../services/auth-service";
import SingleMessage from "./messages/single-message.component";

// import notificationForm from "./notification-form";
import { EnvelopeFill } from "react-bootstrap-icons";
import moment from "moment";

export default class Message extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthService.getCurrentUser(),
      content: [],
      isOpen: false,
      new: 0,
    };
  }

  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  componentDidMount() {
    MessageService.getNewMessages(this.state.currentUser.id).then(
      (response) => {
        // console.log(response.data.data.directMessage);
        this.setState({
          content: response.data.data.directMessage,
          new: response.data.data.directMessage.length,
        });
      },
      (error) => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });
      }
    );
  }

  newDateFormat(rawDate) {
    var stillUtc = moment.utc(rawDate).toDate();

    const fullTimestamp = moment(stillUtc)
      .local()
      .format("MMMM Do YYYY, h:mm:ss a");
    return fullTimestamp;
  }

  render() {
    return (
      <div>
        {/* <Button className="btn-default"></Button> */}

        <Dropdown className="margining btn-sm btn" as={ButtonGroup}>
          <Button href="/messages" variant="success">
            <EnvelopeFill></EnvelopeFill>{" "}
            <span className="circle-notif-red">{this.state.new}</span>
          </Button>

          <Dropdown.Toggle split variant="success" id="dropdown-split-basic" />

          <Dropdown.Menu>
            {this.state.content.map((item) => (
              <Dropdown.Item>
                {this.newDateFormat(item.timestamp)} |{" "}
                {item.userIdfrom.map((sub) => sub.username)} |
                <SingleMessage data={item}></SingleMessage>
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
}
