import React, { Component } from "react";
import { Form, Button, Col, Container } from "react-bootstrap";

class AddressDetails extends Component {
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  saveAndContinue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  render() {
    const itemsActions = [
      "hELP message system",
      "Email (encrypted) ",
      "Instant Message (encrypted)",
      "Social Media (encrypted)",
    ];

    return (
      <Container>
        <Form>
          <Form.Group controlId="formAddress">
            <Form.Label>What is a good way to reach you? </Form.Label>
            <p>Check all that apply.</p>

            <Form.Check
              label={itemsActions[0]}
              value={itemsActions[0]}
              name="internal"
              onChange={this.props.handleChange}
            />
            <Form.Check
              label={itemsActions[1]}
              value={itemsActions[1]}
              name="im"
              onChange={this.props.handleChange}
            />

            <Form.Check
              label={itemsActions[2]}
              value={itemsActions[2]}
              name="email"
              onChange={this.props.handleChange}
            />
            <Form.Check
              label={itemsActions[3]}
              value={itemsActions[3]}
              name="social"
              onChange={this.props.handleChange}
            />
          </Form.Group>
          <Button variant="secondary" onClick={this.back}>
            Back
          </Button>{" "}
          <Button variant="primary" onClick={this.saveAndContinue}>
            Next
          </Button>
        </Form>
      </Container>
    );
  }
}

export default AddressDetails;
