import React, { Component } from "react";

import { Switch, Route, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import {
  PeopleFill,
  EmojiSmileFill,
  CodeSquare,
  PersonCircle,
  House,
  FileTextFill,
} from "react-bootstrap-icons";

import AuthService from "./services/auth-service";

import Login from "./components/login.component";
import Register from "./components/register.component";
import Home from "./components/home.component";
import Profile from "./components/profile.component";
import BoardUser from "./components/board-user.component";
import BoardModerator from "./components/board-moderator.component";
import BoardAdmin from "./components/board-admin.component";
import BoardEmotion from "./components/board-emotions.component";
import BoardProgBehav from "./components/board-prog-behavior.component";
import BoardCourse from "./components/board-course-content";

import UserList from "./components/userlist.component";
import EmotionSelfReport from "./components/features/elp-self-report-component";
import RequestList from "./components/request-list-component";
import Notifications from "./components/notifications-component";
import MailboxNotifications from "./components/messages.notifications.component";
import Mailbox from "./components/messages.components";
import EmoFrust from "./components/prog-behav-emo-context-component/emo-Frust-component";
import EmoConf from "./components/prog-behav-emo-context-component/emo-Confusion-component";
import EmoCuri from "./components/prog-behav-emo-context-component/emo-Curiosity-component";
import EmoDisg from "./components/prog-behav-emo-context-component/emo-Disgust-component";
import EmoEng from "./components/prog-behav-emo-context-component/emo-Engaged-component";
import EmoFear from "./components/prog-behav-emo-context-component/emo-Fear-component";
import EmoNeut from "./components/prog-behav-emo-context-component/emo-Neutral-component";
import EmoSad from "./components/prog-behav-emo-context-component/emo-Sadness-component";
import EmoHapp from "./components/prog-behav-emo-context-component/emo-Happy-component";

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      showUserList: false,
      showSelfReportEmotion: false,
      showEmotionDashboard: false,
      showProgBehav: false,
      showRequestList: false,
      showMessages: false,
      currentUser: undefined,
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
        showModeratorBoard: user.roles.includes("ROLE_MODERATOR"),
        showAdminBoard: user.roles.includes("ROLE_ADMIN"),
        showUserList: user.roles.includes("ROLE_ADMIN"),
        showSelfReportEmotion: user.roles.includes("ROLE_USER"),
        showEmotionDashboard: user.roles.includes("ROLE_USER"),
        showProgBehav: user.roles.includes("ROLE_USER"),
        showRequestList: user.roles.includes("ROLE_USER"),
        showMessages: user.roles.includes("ROLE_USER"),
      });
    }
  }

  logOut() {
    AuthService.logout();
  }

  render() {
    const {
      currentUser,
      showModeratorBoard,
      showAdminBoard,
      showUserList,
      showProgBehav,
      showSelfReportEmotion,
      showEmotionDashboard,
      showRequestList,
      showMessages,
    } = this.state;

    return (
      <div>
        <nav className="navbar navbar-expand navbar-dark bg-dark">
          <div className="navbar-nav mr-auto">
            {showEmotionDashboard && (
              <li className="nav-item">
                <Link to={"/getAllEmotions"} className="nav-link">
                  <PeopleFill></PeopleFill> Your Peer's Emotions
                </Link>
              </li>
            )}
            {currentUser && (
              <li className="nav-item">
                <Link to={"/user"} className="nav-link">
                  <EmojiSmileFill></EmojiSmileFill> Your Emotions
                </Link>
              </li>
            )}
            {currentUser && (
              <li className="nav-item">
                <Link to={"/getUserProgBehavs"} className="nav-link">
                  <CodeSquare></CodeSquare> Your Programming Behavior
                </Link>
              </li>
            )}

            {/* {currentUser && (
              <li className="nav-item">
                <Link to={"/course"} className="nav-link">
                  <FileTextFill></FileTextFill> Course Materials
                </Link>
              </li>
            )} */}
            {/* {showUserList && (
              <li className="nav-item">
                <Link to={"/getUsers"} className="nav-link">
                  User List
                </Link>
              </li>
            )}

            {showModeratorBoard && (
              <li className="nav-item">
                <Link to={"/mod"} className="nav-link">
                  Moderator Board
                </Link>
              </li>
            )}

            {showAdminBoard && (
              <li className="nav-item">
                <Link to={"/admin"} className="nav-link">
                  Admin Board
                </Link>
              </li>
            )} */}
          </div>

          {currentUser ? (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to={"/profile"} className="nav-link">
                  <PersonCircle></PersonCircle> {currentUser.username}
                </Link>
              </li>
              <li className="nav-item">
                <a href="/login" className="nav-link" onClick={this.logOut}>
                  LogOut
                </a>
              </li>
            </div>
          ) : (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to={"/home"} className="nav-link">
                  <House></House> Home
                </Link>
              </li>
              <li className="nav-item">
                <Link to={"/login"} className="nav-link">
                  Login
                </Link>
              </li>

              <li className="nav-item">
                <Link to={"/register"} className="nav-link">
                  Sign Up
                </Link>
              </li>
            </div>
          )}
          {showSelfReportEmotion && (
            <li>
              <EmotionSelfReport></EmotionSelfReport>{" "}
            </li>
          )}
          {showRequestList && (
            <li>
              <Notifications></Notifications>
            </li>
          )}
          {showMessages && (
            <li>
              <MailboxNotifications></MailboxNotifications>
            </li>
          )}
        </nav>
        <div className="container mt-3">
          <Switch>
            <Route exact path={["/", "/home"]} component={Home}></Route>
            <Route exact path="/login" component={Login}></Route>
            <Route
              exact
              path="/getAllEmotions"
              component={BoardEmotion}
            ></Route>
            <Route exact path="/register" component={Register}></Route>
            <Route exact path="/profile" component={Profile}></Route>
            <Route path="/user" component={BoardUser}></Route>
            <Route path="/getUserProgBehavs" component={BoardProgBehav}></Route>
            <Route path="/mod" component={BoardModerator}></Route>
            <Route path="/admin" component={BoardAdmin}></Route>
            <Route path="/course" component={BoardCourse}></Route>
            <Route path="/getUsers" component={UserList}></Route>
            <Route path="/requests" component={RequestList}></Route>
            <Route path="/messages" component={Mailbox}></Route>
            <Route path="/Frustration" component={EmoFrust}></Route>
            <Route path="/Fear" component={EmoFear}></Route>
            <Route path="/Confusion" component={EmoConf}></Route>
            <Route path="/Curiosity" component={EmoCuri}></Route>
            <Route path="/Disgust" component={EmoDisg}></Route>
            <Route path="/Engaged" component={EmoEng}></Route>
            <Route path="/Happiness" component={EmoHapp}></Route>
            <Route path="/Neutral" component={EmoNeut}></Route>
            <Route path="/Sadness" component={EmoSad}></Route>
          </Switch>
        </div>
      </div>
    );
  }
}

export default App;

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <CodeSquare>src/App.js</CodeSquare> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }
