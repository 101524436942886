import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import ReflectionForm from "./reflection-form";
import InterServices from "../../../services/intervention-service";

export default class Reflection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: this.props.data,
      isOpen: false,
    };
    this.handleInterChange = this.handleInterChange.bind(this);
  }

  //openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  componentDidMount() {
    //InterServices.updateToRead(this.state.content._id);
  }

  handleInterChange(e) {
    // const target = event.target;
    // var message = target.value;
    e.preventDefault();

    this.setState({ isOpen: true });
    InterServices.updateToRead(this.state.content._id);
  }

  render() {
    let username = this.state.content.userIdfrom[0].username;

    return (
      <div>
        <Button className="btn-default" onClick={this.handleInterChange}>
          Reflect on experience.
        </Button>
        <Modal show={this.state.isOpen} onHide={this.closeModal}>
          <Modal.Header>
            <Modal.Title>
              Complete these quick reflection questions about your interaction
              with {username}{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ReflectionForm data={this.state.content}></ReflectionForm>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
