import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import ExpressThanksB from "./express-thanks-b-form";
import InterServices from "../../../services/intervention-service";
import ELPUserProfile from "../../features/elp-activity-feed-indiv-profile-img";

export default class GiveThanks extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: this.props.data,
      isOpen: false,
      imgState: "http://localhost:8080/public/UserProfiles/",
    };
    this.handleInterChange = this.handleInterChange.bind(this);
  }

  // openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  componentDidMount() {
    //InterServices.updateToRead(this.state.content._id);
  }

  handleInterChange(e) {
    // const target = event.target;
    // var message = target.value;
    e.preventDefault();

    this.setState({ isOpen: true });
    InterServices.updateToRead(this.state.content._id);
  }

  render() {
    let username = this.state.content.userIdfrom[0].username;
    let profileImg = this.state.content.userIdfrom[0].profileImage;

    return (
      <div>
        {/* <img
          src={this.state.imgState + profileImg}
          alt="profile"
          className="profileImage-tiny"
        />{" "} */}
        <ELPUserProfile
          id={this.state.content.userIdfrom[0]._id}
        ></ELPUserProfile>
        <Button className="btn-success" onClick={this.handleInterChange}>
          Give thanks to {username}
        </Button>
        <Modal show={this.state.isOpen} onHide={this.closeModal}>
          <Modal.Header>
            <Modal.Title>Give thanks to {username} </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ExpressThanksB data={this.state.content}></ExpressThanksB>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
