import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { EnvelopeFill, KanbanFill } from "react-bootstrap-icons";
import { Form } from "react-bootstrap";
import UserService from "./../../services/user-service";
import AuthService from "./../../services/auth-service";
import MessageService from "./../../services/message-service";
import Select from "react-select";
import moment from "moment";

// import HelpGivingForm from "./help-giving-intervention-stepper/stepper-help-give";

export default class NewMessage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthService.getCurrentUser(),
      isOpen: false,
      imgState: "http://localhost:8080/public/UserProfiles/",
      interImg: "http://localhost:8080/public/Interventions/",
      users: [],
      usersEmail: [],
      suggestions: [],
      text: "",
      subject: "",
      recipient: [],
      message: "",
      userData: [],
      sendTo: "",
      values: [],
      checked: false,
    };
    this.handleSubjectChange = this.handleSubjectChange.bind(this);
    this.handleMessageChange = this.handleMessageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  newDateFormat(rawDate) {
    var stillUtc = moment.utc(rawDate).toDate();

    const fullTimestamp = moment(stillUtc)
      .local()
      .format("MMMM Do YYYY, h:mm:ss a");
    return fullTimestamp;
  }

  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  handleSubjectChange(event) {
    const target = event.target;
    var subject = target.value;

    this.setState({ subject });
  }

  handleRecipientChange(recipient) {
    this.setState({ recipient });
    // console.log(this.state.recipient);
  }

  handleMessageChange(event) {
    const target = event.target;
    var message = target.value;

    this.setState({ message });
  }

  getUserID(username) {
    let obj = this.state.userData.find((o) => o.username === username);
    this.state.sendTo = obj._id;

    // console.log(this.state.sendTo);
  }

  onChange = (opt) => {
    const allOptionsSelected = opt.length === this.state.users.length;

    // console.log(this.state.values);
    this.setState({
      checked: allOptionsSelected ? true : false,
      values: opt,
    });
  };

  onChangeCheckbox = (e) => {
    const isChecked = !this.state.checked;
    this.setState({
      checked: isChecked,
      values: isChecked ? this.state.users : this.state.values,
    });
  };

  componentDidMount() {
    UserService.getUserList().then(
      (response) => {
        let users = [];
        let usersEmail = {};

        for (let i = 0; i < response.data.users.length; i++) {
          let sub_data = {};
          sub_data["value"] = response.data.users[i]._id;
          usersEmail[response.data.users[i]._id] = response.data.users[i].email;

          // console.log(response.data.users[i]._id);
          sub_data["label"] = response.data.users[i].username;
          users.push(sub_data);
        }

        this.setState({ users, usersEmail, userData: response.data.users });

        // console.log(response.data.users);
        // console.log("HEEEREEE");
        // console.log(users);
        // console.log(usersEmail);
      },
      (error) => {
        this.setState({
          content:
            (error.response && error.response.data) ||
            error.message ||
            error.toString(),
        });
      }
    );
  }

  handleSubmit(e) {
    e.preventDefault();

    // console.log("test");

    // console.log(this.state.values);

    for (let i = 0; i < this.state.values.length; i++) {
      // console.log(this.state.values[i].value);
      // console.log(this.state.usersEmail[this.state.values[i].value]);

      MessageService.newMessage(
        this.state.values[i].value,
        this.state.currentUser.id,
        this.state.message,
        this.state.subject
      );

      MessageService.newMessageSendGrid(
        this.state.usersEmail[this.state.values[i].value],
        "carla.delira@wsu.edu",
        "hELPd Empathy Tool Message Notification -- " + this.state.subject,
        this.state.message + " -- Message Notification via hELPd Empathy Tool"
      );
    }

    this.setState({ isOpen: false });

    window.location.reload();
  }

  render() {
    // console.log(text + " this " + subject + " that " + message);
    const recipient = this.state.users;

    return (
      <div>
        <Button className="btn-lg" variant="success" onClick={this.openModal}>
          <EnvelopeFill></EnvelopeFill> New Message
        </Button>
        <Modal show={this.state.isOpen} onHide={this.closeModal}>
          <Modal.Header>
            <Modal.Title>
              <EnvelopeFill></EnvelopeFill> New Message
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={this.handleSubmit}>
              {/* <Form> */}
              <Form.Label>Recipient: </Form.Label>

              <Select
                isMulti
                onChange={this.onChange}
                options={recipient}
                value={this.state.values}
              />

              <br></br>
              <Form.Label>Subject Line: </Form.Label>

              <Form.Control
                as="textarea"
                rows={1}
                onChange={this.handleSubjectChange}
              />
              <Form.Label>Message: </Form.Label>

              <Form.Control
                as="textarea"
                rows={3}
                onChange={this.handleMessageChange}
              />

              {/* <button className="btn btn-primary" type="submit"> */}
              <button className="btn btn-primary" onClick={this.handleSubmit}>
                Send
              </button>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
