import axios from "axios";
import authHeader from "./auth-header";
const apiConfig = require("./../config/api.config");

// const API_URL = "https://helpd-empathy-tool.herokuapp.com/api/";
// const URL = "https://helpd-empathy-tool.herokuapp.com/";

const API_URL = apiConfig.API_URL + "/api/";
const URL = apiConfig.API_URL + "/api/";

class InterService {
  generateIntervention(userIdTo, userIdfrom, InterventionType, details) {
    return axios.post(
      API_URL + "generateIntervention",
      {
        userIdTo,
        userIdfrom,
        InterventionType,
        details,
      },
      { headers: authHeader() }
    );
  }

  generateReflection(userIdTo, userIdfrom, InterventionType, reflectionFlag) {
    return axios.post(
      API_URL + "generateIntervention",
      {
        userIdTo,
        userIdfrom,
        InterventionType,
        reflectionFlag,
      },
      { headers: authHeader() }
    );
  }

  // updateToRead(interventionId, read) {
  //   return axios.patch(
  //     API_URL + "updateIntervention/" + interventionId,
  //     {
  //       read,
  //     },
  //     { headers: authHeader() }
  //   );
  // }

  updateToRead(interventionId) {
    return axios.post(API_URL + "updateInterventionRead/" + interventionId);
  }

  getTop3(range) {
    return axios.get(API_URL + "getTop3Inter/" + range, {
      headers: authHeader(),
    });
  }

  getTop3Uni(range) {
    return axios.get(URL + "top3IntUni/" + range, { headers: authHeader() });
  }
}

export default new InterService();
