import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import AuthService from "./../../services/auth-service";
import ELPService from "../../services/elp-service";
import ProgBehavService from "../../services/progbehav-service";
import { Chart } from "react-google-charts";

export default class EmotionDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthService.getCurrentUser(),
      emotionId: this.props.id,
      emotionName: "",
      content: [],
      progBehavTypeList: [],
      isOpen: false,
      countContext: {},
      imgState: "http://localhost:8080/public/UserProfiles/",
    };
  }

  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  componentDidMount() {
    ELPService.filterEmotions(this.state.emotionId).then((response) => {
      const content = response.data.emotions;
      const emotionName = response.data.emotions[0].emotStatus[0].emotion;

      this.setState({
        content,
        emotionName,
      });
    });

    ProgBehavService.getProgTypeList().then((response) => {
      const progBehavTypeList = response.data.progbehav;

      let dictProg = {};

      for (let i = 0; i < progBehavTypeList.length; i++) {
        dictProg[progBehavTypeList[i]._id] = progBehavTypeList[i].progbehavType;
      }

      //console.log(dictProg);

      this.setState({
        progBehavTypeList: dictProg,
      });
    });
  }

  countContext() {
    var dict = {};

    for (let i = 0; i < this.state.content.length; i++) {
      if (this.state.content[i].emotContext in dict) {
        dict[this.state.content[i].emotContext] =
          dict[this.state.content[i].emotContext] + 1;
      } else {
        dict[this.state.content[i].emotContext] = 1;
      }
    }

    let array = [];

    array.push(["Context", "Count"]);

    for (const [key, value] of Object.entries(dict)) {
      let arr2 = [];
      arr2.push(this.state.progBehavTypeList[key]);
      arr2.push(value);

      array.push(arr2);
    }

    return array;
  }

  render() {
    const data = this.countContext();

    const options = {
      chart: {
        title: "Peer experience of " + this.state.emotionName,
      },
    };

    return (
      <div>
        <Button className="btn-default" onClick={this.openModal}>
          {this.state.emotionName}
        </Button>

        <Modal show={this.state.isOpen} onHide={this.closeModal}>
          <Modal.Body>
            <div className="container"></div>
            <div className="row">
              <h1>Your Emotion: {this.state.emotionName}</h1>
            </div>

            <div className="row">
              <Chart
                chartType="Bar"
                width="100%"
                height="400px"
                data={data}
                options={options}
              />
            </div>

            <div className="row">
              <div className="col-sm-4">
                <h6>Count</h6>{" "}
              </div>
              <div className="col-sm-4 ">
                <h6>Context</h6>
              </div>
              <div className="col-sm-4 "></div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
