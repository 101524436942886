import React, { Component } from "react";
import AuthService from "./../../services/auth-service";
import ELPService from "./../../services/elp-service";
import { Card, Tab, Tabs } from "react-bootstrap";
import ProgBehavService from "../../services/progbehav-service";

import { Chart } from "react-google-charts";

export default class EmoFear extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthService.getCurrentUser(),
      emotionName: "",
      content: [],
      UserEmotContext: [],
      progBehavTypeList: [],
      imgState: "http://localhost:8080/public/UserProfiles/",
    };
  }

  componentDidMount() {
    ELPService.filterEmotions("64348f227128ba0014840e0b").then((response) => {
      const content = response.data.emotions;
      const emotionName = response.data.emotions[0].emotStatus[0].emotion;

      //console.log(content);
      //console.log(emotionName);

      this.setState({
        content,
        emotionName,
      });
    });

    ELPService.getSingleEmotionContext("64348f227128ba0014840e0b").then(
      (response) => {
        const UserEmotContext = response.data.emotions;

        //console.log(UserEmotContext);

        this.setState({
          UserEmotContext,
        });
      }
    );

    ProgBehavService.getProgTypeList().then((response) => {
      const progBehavTypeList = response.data.progbehav;

      let dictProg = {};

      for (let i = 0; i < progBehavTypeList.length; i++) {
        dictProg[progBehavTypeList[i]._id] = progBehavTypeList[i].progbehavType;
      }

      //console.log(dictProg);

      this.setState({
        progBehavTypeList: dictProg,
      });
    });
  }

  countContext() {
    var dict = {};

    for (let i = 0; i < this.state.content.length; i++) {
      if (this.state.content[i].emotContext in dict) {
        dict[this.state.content[i].emotContext] =
          dict[this.state.content[i].emotContext] + 1;
      } else {
        dict[this.state.content[i].emotContext] = 1;
      }
    }

    let array = [];

    array.push(["Context", "Count"]);

    for (const [key, value] of Object.entries(dict)) {
      let arr2 = [];
      arr2.push(this.state.progBehavTypeList[key]);
      arr2.push(value);
      array.push(arr2);
    }

    return array;
  }

  render() {
    const data = this.countContext();

    return (
      <div className="container">
        {" "}
        <div className="row">
          <h1>Experiences of Fear</h1>
          <Card>
            <Card.Body>
              <Tabs
                defaultActiveKey="total"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab
                  eventKey="total"
                  title="Total"
                  tabClassName="sub-tab-small"
                >
                  <Chart
                    chartType="Bar"
                    width="100%"
                    height="400px"
                    data={data}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  />
                </Tab>
                <Tab
                  eventKey="unique"
                  title="Unique"
                  tabClassName="sub-tab-small"
                ></Tab>
              </Tabs>
            </Card.Body>
          </Card>
        </div>
        <div className="row">
          {" "}
          <h1>Peers who experienced feeling fear</h1>
          <div className="col-sm-4 tableEmotions">
            <h4>Name</h4>{" "}
          </div>
          <div className="col-sm-4 tableEmotions">
            <h4>Context/Details</h4>
          </div>
          <div className="col-sm-4 tableEmotions">
            <h4>Give Help</h4>
          </div>
        </div>
        {this.state.UserEmotContext.map((item) => (
          <div className="row">
            <div className="col-sm-4">
              {item.userId.map((sub) => (
                <span>{sub.username}</span>
              ))}
            </div>
            <div className="col-sm-4">
              {this.state.progBehavTypeList[item.emotContext]}
            </div>
            <div className="col-sm-4 padding">
              {/* <HelpGiving data={this.state.content}></HelpGiving> */}
            </div>
          </div>
        ))}
      </div>
    );
  }
}
