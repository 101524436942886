import React, { Component } from "react";
import { Form, Button, Col, Container } from "react-bootstrap";
import userService from "../../../../services/user-service";

class UserDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userlist: [],
      content: this.props.data,
      imgState: "http://localhost:8080/public/UserProfiles/",
    };
  }

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  saveAndContinue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };
  componentDidMount() {
    userService.getUserList().then(
      (response) => {
        const userlist = response.data.users;

        this.setState({
          userlist,
        });

        // console.log(this.state.userlist);
        //  console.log(userlist);
      },
      (error) => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });
      }
    );
  }

  render() {
    let username = this.state.content.userIdfrom[0].username;
    const detailsFrom = this.state.content.details;
    let profileImg = this.state.content.userIdfrom[0].profileImage;

    return (
      <Container>
        {/* <img
          src={this.state.imgState + profileImg}
          alt="profile"
          className="profileImage-med"
        />{" "} */}
        <Form>
          <p>
            {username} says that they can help in these ways!
            <br></br>
            <ul>
              {detailsFrom.map((item) => (
                <li> {item}</li>
              ))}
            </ul>
          </p>

          <Button variant="primary" onClick={this.saveAndContinue}>
            Next
          </Button>
        </Form>
      </Container>
    );
  }
}

export default UserDetails;
