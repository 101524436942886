import React, { Component } from "react";
import { Form, Button, Col, Container } from "react-bootstrap";
import ELPUserProfile from "../../../features/elp-activity-feed-indiv-profile-img-md";

class AddressDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: this.props.data,
      imgState: "http://localhost:8080/public/UserProfiles/",
    };
  }
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  saveAndContinue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  render() {
    const itemsSeek = [
      "I want help understanding code.",
      "I want to collaborate/study with you.",
      "I want help/tutoring on course material.",
      "I want emotional support/someone to talk to.",
    ];
    let username = this.state.content.userIdfrom[0].username;
    let profileImg = this.state.content.userIdfrom[0].profileImage;

    return (
      <Container>
        {/* <img
          src={this.state.imgState + profileImg}
          alt="profile"
          className="profileImage-med"
        />{" "} */}
        <ELPUserProfile
          id={this.state.content.userIdfrom[0]._id}
        ></ELPUserProfile>
        <Form>
          <Form.Group controlId="formAddress">
            <Form.Label>
              <h3>Let {username} know how they can help! </h3>
            </Form.Label>
            <p>
              <strong>Check all that apply.</strong>
            </p>

            <Form.Check
              label={itemsSeek[0]}
              value={itemsSeek[0]}
              name="understand"
              onChange={this.props.handleChange}
            />
            <Form.Check
              label={itemsSeek[1]}
              value={itemsSeek[1]}
              name="collaborate"
              onChange={this.props.handleChange}
            />

            <Form.Check
              label={itemsSeek[2]}
              value={itemsSeek[2]}
              name="tutoring"
              onChange={this.props.handleChange}
            />

            <Form.Check
              label={itemsSeek[3]}
              value={itemsSeek[3]}
              name="emotional"
              onChange={this.props.handleChange}
            />
          </Form.Group>
          <Button variant="secondary" onClick={this.back}>
            Back
          </Button>{" "}
          <Button variant="primary" onClick={this.saveAndContinue}>
            Next
          </Button>
        </Form>
      </Container>
    );
  }
}

export default AddressDetails;
