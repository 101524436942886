import axios from "axios";
import authHeader from "./auth-header";
const apiConfig = require("./../config/api.config");

// const API_URL = "http://localhost:8080/api/";
// const URL = "http://localhost:8080/";

// const API_URL = "https://helpd-empathy-tool.herokuapp.com/api/";
// const URL = "https://helpd-empathy-tool.herokuapp.com/";

const API_URL = apiConfig.API_URL + "/api/";
const URL = apiConfig.API_URL + "/api/";

class NotificationService {
  getUserRequests(userId) {
    return axios.get(API_URL + "getUserRequests/" + userId, {
      headers: authHeader(),
    });
  }

  getNewUserRequests(userId) {
    return axios.get(URL + "getNewInterventions/" + userId, {
      headers: authHeader(),
    });
  }

  getUserRequestHistory(userId) {
    return axios.get(API_URL + "getUserHistory/" + userId, {
      headers: authHeader(),
    });
  }

  getUserRequestHistory_HelpGiving(userId) {
    return axios.get(API_URL + "getUserHelpGivingHist/" + userId, {
      headers: authHeader(),
    });
  }

  getUserRequestHistory_HelpSeeking(userId) {
    return axios.get(API_URL + "getUserHelpSeekingHist/" + userId, {
      headers: authHeader(),
    });
  }

  getUserRequests_HelpSeeking(userId) {
    return axios.get(API_URL + "getUserRequestsHelpSeeking/" + userId, {
      headers: authHeader(),
    });
  }

  getUserRequests_HelpGiving(userId) {
    return axios.get(API_URL + "getUserRequestsHelpGiving/" + userId, {
      headers: authHeader(),
    });
  }

  getUserRequestsThree(userId) {
    return axios.get(API_URL + "getUserRequestsThree/" + userId, {
      headers: authHeader(),
    });
  }
}

export default new NotificationService();
