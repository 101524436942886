import React, { Component } from "react";
import { Form } from "react-bootstrap";
import ELPService from "../../services/elp-service";
import ProgListService from "../../services/progbehav-service";
import AuthService from "../../services/auth-service";
import UserService from "../../services/user-service";
import InterService from "../../services/intervention-service";

class EmotionSelfReportForm extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.changeEmotion = this.changeEmotion.bind(this);
    this.changeContext = this.changeContext.bind(this);
    this.changeDetails = this.changeDetails.bind(this);

    const currentUser = AuthService.getCurrentUser();

    this.state = {
      userId: currentUser.id,
      emotStatus: "",
      emotContext: "",
      details: "",
      emotionlist: [],
      programTypeList: [],
      currentUserDets: currentUser,
      currEmo: "",
      persEmo: "",
      emotionPosNeg: {},
    };
  }

  isPositiveOrNegative(unFormated) {
    var dict = {};

    for (let i = 0; i < this.state.emotionlist.length; i++) {
      // //console.log(this.state.emotionlist[i].emotion);
      // //console.log(this.state.emotionlist[i].type);

      dict[this.state.emotionlist[i]._id] = this.state.emotionlist[i].type;
    }

    // //console.log(dict);

    return dict;
  }

  componentDidMount() {
    ELPService.getEmotionList().then(
      (response) => {
        const emotionlist = response.data.emotion;

        this.setState({
          emotionlist,
        });

        // //console.log(this.state.emotionlist);
        //  //console.log("This is the list");

        this.setState({
          emotionPosNeg: this.isPositiveOrNegative(this.state.emotionlist),
        });

        // //console.log(this.state.emotionPosNeg);
      },
      (error) => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });
      }
    );

    ProgListService.getProgTypeList().then(
      (response) => {
        const programTypeList = response.data.progbehav;

        this.setState({
          programTypeList,
        });
      },
      (error) => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });
      }
    );

    UserService.getUser(this.state.userId).then(
      (response) => {
        const currEmo = response.data.data.user.currentEmotion;
        const persEmo = response.data.data.user.persistEmotion;

        this.setState({ currEmo, persEmo });

        //  //console.log(response.data.data.user.currentEmotion);
        //  //console.log(response.data.data.user.persistEmotion);
      },
      (error) => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });
      }
    );
  }

  changeEmotion(e) {
    ////console.log(this.state);

    this.setState({
      emotStatus: e.target.value,
    });
  }

  changeContext(e) {
    //console.log(this.state);
    this.setState({
      emotContext: e.target.value,
    });
  }

  changeDetails(e) {
    //console.log(this.state);
    this.setState({
      details: e.target.value,
    });
  }

  calculateIntervention(curr, persNum, status) {
    let persNumInt = parseInt(persNum);
    if (curr === status) {
      //console.log("This is the previous and same emotion report!");

      if (persNumInt > 4) {
        //console.log("This is a persistent emotion");
        persNumInt = 0;
        this.setState({ persEmo: 0 });
      } else {
        //console.log("This is not a persistent emotion");

        persNumInt = (persNumInt + 1).toString();

        //console.log(persNumInt);

        this.setState({ persEmo: persNumInt });
      }
    } else {
      persNumInt = 0;
      this.setState({ persEmo: 0 });
    }

    return persNumInt;
  }

  isHelpGiveOrAsk(emotionStatus) {}

  handleSubmit(e) {
    e.preventDefault();
    //console.log(this.state.persEmo);

    // console.log(this.state);
    // console.log(this.state.userId);
    // // console.log(this.state.currEmo);
    // console.log(this.state.emotStatus);
    // // console.log(this.state.persEmo);
    // // console.log(this.state.emotionlist);
    // console.log(this.state.details);
    // console.log(this.state.emotContext);

    //console.log(this.state.emotionPosNeg[this.state.emotStatus]);

    var persEmoSend = this.calculateIntervention(
      this.state.currEmo,
      this.state.persEmo,
      this.state.emotStatus
    );

    // console.log(persEmoSend + " This is the persistent Emotion number");

    if (
      this.state.emotionPosNeg[this.state.emotStatus] === "Positive" &&
      persEmoSend >= 5
    ) {
      InterService.generateIntervention(
        this.state.userId,
        this.state.userId,
        "62243e63afde0b0e4c23dda5",
        this.state.emotStatus //Figure this out tomorrow
      );
      // console.log("Send Help-Giving Intervention");
    } else if (
      this.state.emotionPosNeg[this.state.emotStatus] === "Negative" &&
      persEmoSend >= 5
    ) {
      InterService.generateIntervention(
        this.state.userId,
        this.state.userId,
        "622561ecfd22b70edc5aa7c8",
        this.state.emotStatus //Figure this out tomorrow
      );

      //console.log("Send Help-Asking Intervention");
    } else {
      //console.log("No Interventions");
    }

    //Change User Details like CurrEmo and PersEmo
    UserService.updateUserEmotionStatus(
      this.state.userId,
      this.state.emotStatus,
      persEmoSend
    ); //).then(() => {});
    //  console.log(this.state.persEmo);

    ELPService.createEmotions(
      this.state.userId,
      this.state.emotStatus,
      this.state.emotContext,
      this.state.details
    ).then(() => {
      window.location.reload();
    });
  }

  render() {
    //console.log("This is user:");
    //console.log(this.state.currentUserDets.currentEmotion);

    return (
      <div>
        <Form
          onSubmit={this.handleSubmit}
          ref={(c) => {
            this.form = c;
          }}
        >
          <div className="form-group">
            <label htmlFor="emotionAsk">
              <h3>What emotion are you feeling right now?</h3>
            </label>
            <br></br>
            <br></br>

            {/* <select value={this.state.emotStatus} onChange={this.changeEmotion}>
              {this.state.emotionlist.map((item) => (
                <option value={item._id}>{item.emotion}</option>
              ))}
            </select> */}

            <ul>
              {this.state.emotionlist.map((item) => (
                <li className="side-by-side">
                  <input
                    type="radio"
                    name="emotion"
                    value={item._id}
                    id={item._id + "-label"}
                    onChange={this.changeEmotion}
                  ></input>
                  <label for={item._id + "-label"}>
                    <img
                      src={
                        "https://publichelpdet.s3.us-west-2.amazonaws.com/" +
                        item.image
                      }
                      height="50"
                      width="50"
                    />{" "}
                    {item.emotion}
                  </label>
                </li>
              ))}
            </ul>
          </div>
          <div className="form-group">
            <label htmlFor="context">
              <h3>Why?</h3>
            </label>
            <br></br>
            <select
              value={this.state.emotContext}
              onChange={this.changeContext}
            >
              {this.state.programTypeList.map((item) => (
                <option value={item._id}>{item.progbehavType}</option>
              ))}
            </select>
            {/* <select
              value={this.state.emotContext}
              onChange={this.changeContext}
            >
              <option value="Reason1">Reason1</option>
              <option value="Reason2">Reason2</option>
              <option value="Reason3">Reason3</option>
              <option value="Reason4">Reason4</option>
            </select> */}
          </div>
          <div className="form-group">
            <label htmlFor="details">
              <h3>Details:</h3>
            </label>
            <textarea
              value={this.state.details}
              onChange={this.changeDetails}
              rows="4"
              cols="50"
            ></textarea>
          </div>
          <div className="form-group">
            <input
              className="btn btn-primary btn-sm"
              type="submit"
              value="Submit"
            />
          </div>
        </Form>
      </div>
    );
  }
}

export default EmotionSelfReportForm;
