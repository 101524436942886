import React, { Component } from "react";
import AuthService from "./../../services/auth-service";
import ProgBehavService from "./../../services/progbehav-service";
// import { Chart } from "react-google-charts";
import { Card, Tab, Tabs } from "react-bootstrap";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export default class ProgBehavDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthService.getCurrentUser(),
      progBehavName: "",
      progBehavAll: [],
      progBehavMonth: [],
      progBehavWeek: [],
      progBehavDay: [],
      countContext: {},
      // imgState: "http://localhost:8080/public/UserProfiles/",
    };
  }

  componentDidMount() {
    ProgBehavService.getUserProgBehCount(this.state.currentUser.id, "any").then(
      (response) => {
        const progBehavAll = response.data.data.count;
        // console.log("HEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEERE");
        // console.log(progBehavAll);
        this.setState({
          progBehavAll,
        });
      }
    );

    ProgBehavService.getUserProgBehCount(
      this.state.currentUser.id,
      "week"
    ).then((response) => {
      const progBehavWeek = response.data.data.count;
      this.setState({
        progBehavWeek,
      });

      // console.log(progBehavWeek);
    });

    ProgBehavService.getUserProgBehCount(
      this.state.currentUser.id,
      "month"
    ).then((response) => {
      const progBehavMonth = response.data.data.count;
      this.setState({
        progBehavMonth,
      });
    });

    ProgBehavService.getUserProgBehCount(this.state.currentUser.id, "day").then(
      (response) => {
        const progBehavDay = response.data.data.count;
        this.setState({
          progBehavDay,
        });
      }
    );
    // ProgBehavService.filterprogBehavs(this.state.emotionId).then((response) => {
    //   const content = response.data.progBehavs;
    //   const progBehavName = response.data.progBehavs[0].emotStatus[0].emotion;
    //   console.log(content);
    //   console.log("there");
    //   console.log(progBehavName);

    // });
  }

  //   countContext() {
  //     var dict = {};

  //     for (let i = 0; i < this.state.content.length; i++) {
  //       console.log(this.state.content[i].emotContext);
  //       console.log("inside loop");

  //       if (this.state.content[i].emotContext in dict) {
  //         dict[this.state.content[i].emotContext] =
  //           dict[this.state.content[i].emotContext] + 1;
  //       } else {
  //         dict[this.state.content[i].emotContext] = 1;
  //       }
  //     }

  //     console.log(dict);

  //     let array = [];

  //     array.push(["Context", "Count"]);

  //     for (const [key, value] of Object.entries(dict)) {
  //       console.log(key, value);
  //       let arr2 = [];
  //       arr2.push(key);
  //       arr2.push(value);

  //       console.log(arr2);

  //       array.push(arr2);
  //     }

  //     console.log(array);

  //     return array;
  //   }

  render() {
    //console.log(this.state.progBehavAll);
    //console.log(this.state.progBehavDay);
    //console.log(this.state.progBehavWeek);

    const dataAll = this.state.progBehavAll;
    const dataWeek = this.state.progBehavWeek;
    const dataMonth = this.state.progBehavMonth;
    const dataDay = this.state.progBehavDay;

    return (
      <div>
        <Tabs
          defaultActiveKey="day"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="day" title="Day" tabClassName="sub-tab-small">
            <BarChart
              width={500}
              height={300}
              data={dataDay}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="progBehavName" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="count" fill="#7ac6f5" />
            </BarChart>
          </Tab>
          <Tab eventKey="week" title="Week" tabClassName="sub-tab-small">
            <BarChart
              width={500}
              height={300}
              data={dataWeek}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="progBehavName" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="count" fill="#7ac6f5" />
            </BarChart>
          </Tab>
          <Tab eventKey="month" title="Month" tabClassName="sub-tab-small">
            <BarChart
              width={500}
              height={300}
              data={dataMonth}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="progBehavName" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="count" fill="#7ac6f5" />
            </BarChart>
          </Tab>
          <Tab eventKey="all" title="All Time" tabClassName="sub-tab-small">
            <BarChart
              width={500}
              height={300}
              data={dataAll}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="progBehavName" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="count" fill="#7ac6f5" />
            </BarChart>
          </Tab>
        </Tabs>
      </div>
    );
  }
}
