import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import AuthService from "../../../services/auth-service";
import ActionAForm from "./actiona-stepper/stepper-action-a";
import { ArrowLeftRight } from "react-bootstrap-icons";
import InterServices from "../../../services/intervention-service";
import ELPUserProfile from "../../features/elp-activity-feed-indiv-profile-img";

export default class ActionA extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthService.getCurrentUser(),
      content: this.props.data,
      isOpen: false,
      imgState: "http://localhost:8080/public/UserProfiles/",
    };

    this.handleInterChange = this.handleInterChange.bind(this);
  }

  // openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  componentDidMount() {}

  handleInterChange(e) {
    // const target = event.target;
    // var message = target.value;
    e.preventDefault();

    this.setState({ isOpen: true });
    InterServices.updateToRead(this.state.content._id);
  }

  render() {
    let username = this.state.content.userIdfrom[0].username;
    let profileImg = this.state.content.userIdfrom[0].profileImage;

    return (
      <div>
        {/* <img
          src={this.state.imgState + profileImg}
          alt="profile"
          className="profileImage-tiny"
        />{" "} */}
        <ELPUserProfile
          id={this.state.content.userIdfrom[0]._id}
        ></ELPUserProfile>
        <Button className="btn-success" onClick={this.handleInterChange}>
          <ArrowLeftRight></ArrowLeftRight> Connect with {username}!
        </Button>
        {/* This is where you will change up what button and intervention will
        show! */}
        <Modal show={this.state.isOpen} onHide={this.closeModal}>
          <Modal.Header>
            <Modal.Title>
              {username} can help you in these way! Connect with {username}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ActionAForm data={this.state.content}></ActionAForm>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
