import React, { Component } from "react";
import UserService from "../services/user-service";
import { Button } from "react-bootstrap";

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: "",
    };
  }

  componentDidMount() {
    UserService.getPublicContent().then(
      (response) => {
        this.setState({
          content: response.data,
        });
      },
      (error) => {
        this.setState({
          content:
            (error.response && error.response.data) ||
            error.message ||
            error.toString(),
        });
      }
    );
  }

  render() {
    return (
      <div>
        {" "}
        <div className="row">
          <div className="col">
            {" "}
            <div className="logo landingPanel"></div>
          </div>
          <div className="col">
            <h3 className="landingPage">hELPd Empathy Tool</h3>
            <p></p>
            <Button href="/register" className="center">
              Register for hELP Empathy Tool
            </Button>{" "}
          </div>
        </div>
        <div className="row"></div>
      </div>
    );
  }
}
