import React, { Component } from "react";
import UserService from "../../services/user-service";
import { Form } from "react-bootstrap";

export default class ChangeRole extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userDetails: this.props.data,
      roles: [],
      isOpen: false,
      newRole: "6143a6d8a2654a45d4ae9ed0",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    //console.log(this.state.newRole);

    this.setState({
      newRole: e.target.value,
    });
    //console.log(this.state.newRole);
  }

  handleSubmit(e) {
    e.preventDefault();

    let rolesList = ["6143a6d8a2654a45d4ae9ed0"];

    //console.log(this.state.newRole);

    if (this.state.newRole === "6143a6d8a2654a45d4ae9ed1") {
      rolesList.push("6143a6d8a2654a45d4ae9ed1");
    } else {
      rolesList.push("6143a6d8a2654a45d4ae9ed2");
    }

    UserService.updateRole(this.state.userDetails, rolesList).then(() => {
      window.location.reload();
    });
  }

  componentDidMount() {
    UserService.getUser(this.state.userDetails).then((response) => {
      let roles = response.data.data.user.roles;
      //   //console.log(response.data.data.user.roles);
      //console.log("This is a role : ");
      //console.log(roles);
      this.setState({ roles });
      //console.log(this.state.roles);
    });
  }

  render() {
    return (
      <div>
        {/* <form onSubmit={this.handleSubmit}>
          <label>
            <select value={this.state.newRole} onChange={this.handleChange}>
              <option value="6143a6d8a2654a45d4ae9ed0">Student</option>
              <option value="6143a6d8a2654a45d4ae9ed1">
                Teaching Assistant
              </option>
              <option value="6143a6d8a2654a45d4ae9ed2">Instructor</option>
            </select>
          </label>
          <input type="submit" value="Change Role" />
        </form> */}

        <Form
          onSubmit={this.handleSubmit}
          ref={(c) => {
            this.form = c;
          }}
        >
          <div className="form-group">
            <select value={this.state.newRole} onChange={this.handleChange}>
              <option value="6143a6d8a2654a45d4ae9ed0">Student</option>
              <option value="6143a6d8a2654a45d4ae9ed1">
                Teaching Assistant
              </option>
              <option value="6143a6d8a2654a45d4ae9ed2">Instructor</option>
            </select>

            <input
              className="btn btn-primary btn-sm"
              type="submit"
              value="Submit"
            />
          </div>
        </Form>
      </div>
    );
  }
}
