module.exports = {
  //   HOST: "127.0.0.1",
  //   PORT: 27017,
  //   DB: "data",
  //   BUCKET_NAME: "help-elpdapp",
  //   BUCKET_REGION: "us-west-2",
  //   ACCESS_KEY: "AKIATE6O232F27DOHVYU",
  //   SECRET_ACCESS_KEY: "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",
  //API_URL: "http://localhost:8080",
	API_URL: "https://helpd-empathy.eecs.wsu.edu",
        FE_URL: "https://helpd-empathy.eecs.wsu.edu",
};

//const API_URL = "https://helpd-empathy-tool.herokuapp.com/api/auth/";
