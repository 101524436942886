import React, { Component } from "react";
import UserDetails from "./userdetails";
import RequestDetails from "./requests";
import RequestHelpDetails from "./requests-help";
import Confirmation from "./confirmation";

class MultiStepForm extends Component {
  state = {
    step: 1,
    chosenUser: "",
    understand: "",
    collaborate: "",
    tutoring: "",
    similar: "",
    empathy: "",
    content: this.props.data,
  };

  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });
  };

  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const {
      step,
      chosenUser,
      understand,
      collaborate,
      tutoring,
      similar,
      empathy,
    } = this.state;
    const inputValues = {
      chosenUser,
      understand,
      collaborate,
      tutoring,
      similar,
      empathy,
    };
    switch (step) {
      case 1:
        return (
          <UserDetails
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            inputValues={inputValues}
            data={this.state.content}
          ></UserDetails>
        );
      case 2:
        return (
          <RequestDetails
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            inputValues={inputValues}
            data={this.state.content}
          ></RequestDetails>
        );
      case 3:
        return (
          <RequestHelpDetails
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            inputValues={inputValues}
            data={this.state.content}
          ></RequestHelpDetails>
        );
      case 4:
        return (
          <Confirmation
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            inputValues={inputValues}
            data={this.state.content}
          ></Confirmation>
        );
    }
  }
}

export default MultiStepForm;
