import React, { Component } from "react";
import ELPService from "../../services/elp-service";
import RelativeEmotions from "./../elp-features-comparision/relative-emotions-component";
import { Card, Tab, Tabs } from "react-bootstrap";

import apiConfig from "../../config/api.config";

import { Chart } from "react-google-charts";

export default class ELPVisualizationPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dataUni: [],
      dataDay: [],
      dataUniDay: [],
      dataWeek: [],
      dataUniWeek: [],
      dataMonth: [],
      dataUniMonth: [],
      chartData: [],
      emotionContext: [],
      emotionContextUni: [],
      emotionContextDay: [],
      emotionContextUniDay: [],
      emotionContextMonth: [],
      emotionContextUniMonth: [],
      emotionContextWeek: [],
      emotionContextUniWeek: [],
      currentEmotions: [],
      contentContext: [],
      emotionName: "",
    };
  }

  componentDidMount() {
    ELPService.getTotalEmotions("all").then((response) => {
      const content = response.data.emotions;

      this.chartDataPrep(content);

      this.setState({
        data: content,
      });
    });

    ELPService.getUniqueEmotion("all").then((response) => {
      const dataUni = response.data.emotions;

      this.setState({
        dataUni,
      });
    });

    ELPService.getUniqueEmotion("week").then((response) => {
      const dataUniWeek = response.data.emotions;

      this.setState({
        dataUniWeek,
      });
    });

    ELPService.getTotalEmotions("week").then((response) => {
      const content = response.data.emotions;

      this.chartDataPrep(content);

      this.setState({
        dataWeek: content,
      });
    });

    ELPService.getUniqueEmotion("day").then((response) => {
      const dataUniDay = response.data.emotions;

      this.setState({
        dataUniDay,
      });
    });

    ELPService.getTotalEmotions("day").then((response) => {
      const content = response.data.emotions;

      this.chartDataPrep(content);

      this.setState({
        dataDay: content,
      });
    });

    ELPService.getUniqueEmotion("month").then((response) => {
      const dataUniMonth = response.data.emotions;

      this.setState({
        dataUniMonth,
      });
    });

    ELPService.getTotalEmotions("month").then((response) => {
      const content = response.data.emotions;

      this.chartDataPrep(content);

      this.setState({
        dataMonth: content,
      });
    });

    ELPService.getEmotionContext("all").then((response) => {
      let emotionContext = response.data.emotionCount;

      this.setState({
        emotionContext,
      });
    });

    ELPService.getEmotionContextUni("all").then((response) => {
      let emotionContextUni = response.data.emotionCount;

      this.setState({
        emotionContextUni,
      });
    });

    ELPService.getEmotionContext("week").then((response) => {
      let emotionContextWeek = response.data.emotionCount;

      this.setState({
        emotionContextWeek,
      });
    });

    ELPService.getEmotionContextUni("week").then((response) => {
      let emotionContextUniWeek = response.data.emotionCount;

      this.setState({
        emotionContextUniWeek,
      });
    });

    ELPService.getEmotionContext("day").then((response) => {
      let emotionContextDay = response.data.emotionCount;

      this.setState({
        emotionContextDay,
      });
    });

    ELPService.getEmotionContextUni("day").then((response) => {
      let emotionContextUniDay = response.data.emotionCount;

      this.setState({
        emotionContextUniDay,
      });
    });

    ELPService.getEmotionContext("month").then((response) => {
      let emotionContextMonth = response.data.emotionCount;

      this.setState({
        emotionContextMonth,
      });
    });

    ELPService.getEmotionContextUni("month").then((response) => {
      let emotionContextUniMonth = response.data.emotionCount;

      this.setState({
        emotionContextUniMonth,
      });
    });

    /// Maybe not this!
    ELPService.filterEmotions(this.state.emotionId).then((response) => {
      const contentContext = response.data.emotions;
      const emotionName = response.data.emotions[0].emotStatus[0].emotion;

      // console.log(contentContext);
      // console.log("there");
      // console.log(emotionName);

      this.setState({
        contentContext,
        emotionName,
      });
    });
  }

  countEmotContext(emotionData) {
    let headerEmo = [];
    let emoContext = [];
    let EmotionContextDic = {};

    for (let j = 0; j < emotionData.length; j++) {
      if (headerEmo.includes(emotionData[j].emotionName) === false) {
        headerEmo.push(emotionData[j].emotionName);
        EmotionContextDic[emotionData[j].emotionName] = {};
      }
    }

    for (let i = 0; i < emotionData.length; i++) {
      let getLastCount = EmotionContextDic[emotionData[i].emotionName];

      if (emoContext.includes(emotionData[i].progbehavType) === false) {
        emoContext.push(emotionData[i].progbehavType);

        getLastCount[emotionData[i].progbehavType] = emotionData[i].count;

        EmotionContextDic[emotionData[i].emotionName] = getLastCount;
      } else {
        getLastCount[emotionData[i].progbehavType] = emotionData[i].count;

        EmotionContextDic[emotionData[i].emotionName] = getLastCount;
      }
    }

    let arrayBuilderContext = {};

    for (let l = 0; l < emoContext.length; l++) {
      arrayBuilderContext[emoContext[l]] = emoContext[l];
    }

    for (let k = 0; k < headerEmo.length; k++) {
      for (let l = 0; l < emoContext.length; l++) {
        let contextEntry = EmotionContextDic[headerEmo[k]];

        if (contextEntry[emoContext[1]] === undefined) {
          arrayBuilderContext[emoContext[l]] =
            arrayBuilderContext[emoContext[l]] + "," + 0;
        } else {
          arrayBuilderContext[emoContext[l]] =
            arrayBuilderContext[emoContext[l]] +
            "," +
            contextEntry[emoContext[l]];
        }
      }
    }

    let finalArrayEmoContext = [];

    headerEmo.unshift("Context");

    finalArrayEmoContext.push(headerEmo);

    for (const [key, value] of Object.entries(arrayBuilderContext)) {
      finalArrayEmoContext.push(value.split(","));
    }

    // console.log(finalArrayEmoContext);

    return finalArrayEmoContext;
  }

  createHTMLDisplay(emotion, count, id, context, unique) {
    let valueLoc;

    let buildDetails = "";

    try {
      for (let i = 0; i < context.length; i++) {
        if (context[i].emotionName === emotion) {
          for (
            let m = 0;
            m < Object.keys(context[i].progbehaviors).length;
            m++
          ) {
            let newStat =
              "<br><span class='circle-stats'>" +
              context[i].progbehaviors[
                Object.keys(context[i].progbehaviors)[m]
              ] +
              " </span> experienced " +
              Object.keys(context[i].progbehaviors)[m] +
              "<br>";

            buildDetails = buildDetails + newStat;
          }
        }
      }
    } catch {
      console.log("error");
    }

    let link = "href='" + apiConfig.FE_URL + "/" + emotion + "'";

    let repType;

    if (unique === 0) {
      repType = " experiences of feeling ";
    } else {
      repType = " person(s) experienced feeling ";
    }

    return (
      "<div>" +
      "<p style='margin:30px'><h3>" +
      count +
      repType +
      emotion.toLowerCase() +
      ".</h3>" +
      buildDetails +
      "</p><a class='btn btn-primary' " +
      link +
      "> See more details</a></div>"
    );
  }

  chartDataPrep(content, context, unique) {
    let dataChart = [];

    dataChart.push([
      "Emotion",
      "Count",
      { role: "tooltip", type: "string", p: { html: true } },
    ]);
    for (let i = 0; i < content.length; i++) {
      let dataChartEntry = [];
      dataChartEntry.push(content[i].emotionName);
      dataChartEntry.push(content[i].count);
      dataChartEntry.push(
        this.createHTMLDisplay(
          content[i].emotionName,
          content[i].count,
          content[i]._id,
          context,
          unique
        )
      );

      dataChart.push(dataChartEntry);
    }
    return dataChart;
  }

  render() {
    let dataChart = this.chartDataPrep(
      this.state.data,
      this.state.emotionContext,
      0
    );

    let dataChartUni = this.chartDataPrep(
      this.state.dataUni,
      this.state.emotionContextUni,
      1
    );

    let dataChartDay = this.chartDataPrep(
      this.state.dataDay,
      this.state.emotionContextDay,
      0
    );

    let dataChartUniDay = this.chartDataPrep(
      this.state.dataUniDay,
      this.state.emotionContextUniDay,
      1
    );

    let dataChartWeek = this.chartDataPrep(
      this.state.dataWeek,
      this.state.emotionContextWeek,
      0
    );

    let dataChartUniWeek = this.chartDataPrep(
      this.state.dataUniWeek,
      this.state.emotionContextUniWeek,
      1
    );

    let dataChartMonth = this.chartDataPrep(
      this.state.dataMonth,
      this.state.emotionContext,
      0
    );

    let dataChartUniMonth = this.chartDataPrep(
      this.state.dataUniMonth,
      this.state.emotionContextUniMonth,
      1
    );

    return (
      <div>
        <Tabs
          defaultActiveKey="all"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="all" title="All Time" tabClassName="sub-tab-small">
            <Tabs
              defaultActiveKey="Total"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab
                eventKey="Total"
                title="Total"
                tabClassName="sub-tab-smaller"
              >
                {" "}
                <Chart
                  // width={550}
                  // height={550}
                  chartType="PieChart"
                  loader={<div>Loading Chart</div>}
                  data={dataChart}
                  options={{
                    tooltip: { isHtml: true, trigger: "selection" },
                    backgroundColor: "transparent",
                    chartArea: { left: 15, top: 15, right: 0, bottom: 0 },
                    height: 500,
                    width: 500,
                  }}
                />
              </Tab>

              <Tab
                eventKey="Unique"
                title="Unique"
                tabClassName="sub-tab-smaller"
              >
                {" "}
                <Chart
                  // width={550}
                  // height={550}
                  chartType="PieChart"
                  loader={<div>Loading Chart</div>}
                  data={dataChartUni}
                  options={{
                    tooltip: { isHtml: true, trigger: "selection" },
                    backgroundColor: "transparent",
                    chartArea: { left: 15, top: 15, right: 0, bottom: 0 },
                    height: 500,
                    width: 500,
                  }}
                />
              </Tab>
            </Tabs>
          </Tab>{" "}
          <Tab eventKey="month" title="Month" tabClassName="sub-tab-small">
            <Tabs
              defaultActiveKey="Total"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab
                eventKey="Total"
                title="Total"
                tabClassName="sub-tab-smaller"
              >
                {" "}
                <Chart
                  // width={550}
                  // height={550}
                  chartType="PieChart"
                  loader={<div>Loading Chart</div>}
                  data={dataChartMonth}
                  options={{
                    tooltip: { isHtml: true, trigger: "selection" },
                    backgroundColor: "transparent",
                    chartArea: { left: 15, top: 15, right: 0, bottom: 0 },
                    height: 500,
                    width: 500,
                  }}
                />
              </Tab>

              <Tab
                eventKey="Unique"
                title="Unique"
                tabClassName="sub-tab-smaller"
              >
                <Chart
                  // width={550}
                  // height={550}
                  chartType="PieChart"
                  loader={<div>Loading Chart</div>}
                  data={dataChartUniMonth}
                  options={{
                    tooltip: { isHtml: true, trigger: "selection" },
                    backgroundColor: "transparent",
                    chartArea: { left: 15, top: 15, right: 0, bottom: 0 },
                    height: 500,
                    width: 500,
                  }}
                />
              </Tab>
            </Tabs>
          </Tab>{" "}
          <Tab eventKey="week" title="Week" tabClassName="sub-tab-small">
            <Tabs
              defaultActiveKey="Total"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab
                eventKey="Total"
                title="Total"
                tabClassName="sub-tab-smaller"
              >
                <Chart
                  // width={550}
                  // height={550}
                  chartType="PieChart"
                  loader={<div>Loading Chart</div>}
                  data={dataChartWeek}
                  options={{
                    tooltip: { isHtml: true, trigger: "selection" },
                    backgroundColor: "transparent",
                    chartArea: { left: 15, top: 15, right: 0, bottom: 0 },
                    height: 500,
                    width: 500,
                  }}
                />
              </Tab>

              <Tab
                eventKey="Unique"
                title="Unique"
                tabClassName="sub-tab-smaller"
              >
                <Chart
                  // width={550}
                  // height={550}
                  chartType="PieChart"
                  loader={<div>Loading Chart</div>}
                  data={dataChartUniWeek}
                  options={{
                    tooltip: { isHtml: true, trigger: "selection" },
                    backgroundColor: "transparent",
                    chartArea: { left: 15, top: 15, right: 0, bottom: 0 },
                    height: 500,
                    width: 500,
                  }}
                />
              </Tab>
            </Tabs>
          </Tab>{" "}
          <Tab eventKey="day" title="Day" tabClassName="sub-tab-small">
            <Tabs
              defaultActiveKey="Total"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab
                eventKey="Total"
                title="Total"
                tabClassName="sub-tab-smaller"
              >
                {" "}
                <Chart
                  // width={550}
                  // height={550}
                  chartType="PieChart"
                  loader={<div>Loading Chart</div>}
                  data={dataChartDay}
                  options={{
                    tooltip: { isHtml: true, trigger: "selection" },
                    backgroundColor: "transparent",
                    chartArea: { left: 15, top: 15, right: 0, bottom: 0 },
                    height: 500,
                    width: 500,
                  }}
                />
              </Tab>

              <Tab
                eventKey="Unique"
                title="Unique"
                tabClassName="sub-tab-smaller"
              >
                <Chart
                  // width={550}
                  // height={550}
                  chartType="PieChart"
                  loader={<div>Loading Chart</div>}
                  data={dataChartUniDay}
                  options={{
                    tooltip: { isHtml: true, trigger: "selection" },
                    backgroundColor: "transparent",
                    chartArea: { left: 15, top: 15, right: 0, bottom: 0 },
                    height: 500,
                    width: 500,
                  }}
                />
              </Tab>
            </Tabs>
          </Tab>{" "}
        </Tabs>
      </div>
    );
  }
}
