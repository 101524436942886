import React, { Component } from "react";
import { Tabs, Tab, Button } from "react-bootstrap";
import AuthService from "./../services/auth-service";
import MessageService from "../services/message-service";
import SingleMessage from "./../components/messages/single-message.component";
import SentMessage from "./messages/sent.message.component";
import NewMessage from "./messages/new.message.component";
import DeleteMessage from "./messages/delete-message-component";
import ELPUserProfile from "./features/elp-activity-feed-indiv-profile-img";
import moment from "moment";

export default class RequestList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthService.getCurrentUser(),
      inbox: [],
      outbox: [],
      imgState: "http://localhost:8080/public/UserProfiles/",
    };
  }

  componentDidMount() {
    MessageService.inbox(this.state.currentUser.id).then(
      (response) => {
        // console.log(response.data.data.directMessage);
        this.setState({
          inbox: response.data.data.directMessage,
        });
      },
      (error) => {
        this.setState({
          inbox:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });
      }
    );

    MessageService.outbox(this.state.currentUser.id).then(
      (response) => {
        // console.log(response.data.data.directMessage);
        this.setState({
          outbox: response.data.data.directMessage,
        });
      },
      (error) => {
        this.setState({
          outbox:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });
      }
    );
  }

  newDateFormat(rawDate) {
    var stillUtc = moment.utc(rawDate).toDate();

    const fullTimestamp = moment(stillUtc)
      .local()
      .format("MMMM Do YYYY, h:mm:ss a");
    return fullTimestamp;
  }

  isReadMarker(read) {
    let readMessage = "";
    if (read === "true") {
      readMessage = "";
    } else {
      readMessage = "New!";
    }
    return readMessage;
  }

  render() {
    // console.log("Here");
    // console.log(this.state.inbox);

    return (
      <div className="container">
        <h1> Messages</h1>
        <div className="row">
          {" "}
          <div className="col-sm-2 "></div>
          <div className="col-sm-2"></div> <div className="col-sm-2"></div>{" "}
          <div className="col-sm-2">
            <NewMessage></NewMessage>{" "}
          </div>
        </div>
        <div className="row">
          <Tabs
            defaultActiveKey="new-requests"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="new-requests" title="Inbox">
              <div className="row">
                <div className="col-sm-2 tableEmotions">
                  <h3>Sender</h3>{" "}
                </div>
                <div className="col-sm-2 tableEmotions">
                  <h3>Subject Line</h3>
                </div>
                <div className="col-sm-2 tableEmotions">
                  <h3>Time</h3>
                </div>
              </div>

              {this.state.inbox.map((item) => (
                <div className="row">
                  <div className="col-sm-2">
                    {" "}
                    <ELPUserProfile
                      id={item.userIdfrom[0]._id}
                    ></ELPUserProfile>
                    {item.userIdfrom[0].username}
                  </div>
                  <div className="col-sm-2">
                    <span className="new-marker">
                      {this.isReadMarker(item.read)}
                    </span>
                    <SingleMessage data={item}></SingleMessage>
                    {/* {item.subLine} */}
                  </div>
                  <div className="col-sm-2">
                    {this.newDateFormat(item.timestamp)}
                  </div>
                </div>
              ))}
            </Tab>
            <Tab eventKey="sent" title="Sent">
              {" "}
              <div className="row">
                <div className="col-sm-2 tableEmotions">
                  <h3>Sent to</h3>
                </div>
                <div className="col-sm-2 tableEmotions">
                  <h3>Subject Line</h3>
                </div>
                <div className="col-sm-2 tableEmotions">
                  <h3>Time</h3>
                </div>
              </div>
              {this.state.outbox.map((item) => (
                <div className="row">
                  <div className="col-sm-2">
                    {" "}
                    <ELPUserProfile id={item.userIdTo[0]._id}></ELPUserProfile>
                    {item.userIdTo[0].username}
                  </div>
                  <div className="col-sm-2">
                    <SentMessage data={item}></SentMessage>
                  </div>
                  <div className="col-sm-2">
                    {this.newDateFormat(item.timestamp)}
                  </div>
                </div>
              ))}
            </Tab>
          </Tabs>
        </div>
      </div>
    );
  }
}
