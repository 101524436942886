import React, { Component } from "react";
import { Form, Button, Col, Container } from "react-bootstrap";

class AddressDetails extends Component {
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  saveAndContinue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  render() {
    const itemsActions = [
      "I can study/collaborate with you.",
      "I can tutor you.",
    ];

    return (
      <Container>
        <img
          src="https://publichelpdet.s3.us-west-2.amazonaws.com/help-color.png"
          alt="profile"
          className="profileImage-inter"
        />
        <Form>
          <Form.Group controlId="formAddress">
            <Form.Label>
              <h3>Let them know you can help.</h3>{" "}
            </Form.Label>
            <p>
              <strong>Check all that apply.</strong>
            </p>

            <Form.Check
              label={itemsActions[0]}
              value={itemsActions[0]}
              name="collaborate"
              onChange={this.props.handleChange}
            />
            <Form.Check
              label={itemsActions[1]}
              value={itemsActions[1]}
              name="tutoring"
              onChange={this.props.handleChange}
            />
          </Form.Group>
          <Button variant="secondary" onClick={this.back}>
            Back
          </Button>{" "}
          <Button variant="primary" onClick={this.saveAndContinue}>
            Next
          </Button>
        </Form>
      </Container>
    );
  }
}

export default AddressDetails;
