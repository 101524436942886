import React, { Component } from "react";
import RequestDetails from "./requests";
import Confirmation from "./confirmation";

class MultiStepForm extends Component {
  state = {
    step: 1,
    understand: "",
    collaborate: "",
    tutoring: "",
    emotional: "",
    content: this.props.data,
  };

  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });
  };

  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { step, understand, collaborate, tutoring, emotional } = this.state;
    const inputValues = {
      understand,
      collaborate,
      tutoring,
      emotional,
    };
    switch (step) {
      case 1:
        return (
          <RequestDetails
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            inputValues={inputValues}
            data={this.state.content}
          ></RequestDetails>
        );
      case 2:
        return (
          <Confirmation
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            inputValues={inputValues}
            data={this.state.content}
          ></Confirmation>
        );
    }
  }
}

export default MultiStepForm;
