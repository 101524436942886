import React, { Component } from "react";
import ELPService from "../services/elp-service";
import ELPVisualizationPanel from "./features/elp-visualization-panel-component";
import ELPActivityFeed from "./features/elp-activity-feed-component";
import ELPCalendars from "./features/elp-calendar-component";
import ProgBehavService from "../services/progbehav-service";
import InterventionService from "../services/intervention-service";
// import BarChartEmotions from "../components/elp-features-comparision/user-emotions-component-visual";
import BarChartEmotions from "../components/elp-features-comparision/user-emotions-component-visual";
import { Card, Tab, Tabs } from "react-bootstrap";
import AuthService from "../services/auth-service";
import UserService from "../services/user-service";
import NotificationService from "./../services/notification-service";
import { Link } from "react-router-dom";
import moment from "moment";

export default class BoardEmotion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthService.getCurrentUser(),
      userDetails: "",
      emotionList: "",
      content: [],
      topThreeEmotion: [],
      topThreeEmotionWeek: [],
      topThreeEmotionMonth: [],
      topThreeEmotionDay: [],
      topThreeProg: [],
      topThreeProgWeek: [],
      topThreeProgMonth: [],
      topThreeProgDay: [],
      topThreeSocials: [],
      topThreeSocialsWeek: [],
      topThreeSocialsMonth: [],
      topThreeSocialsDay: [],
      topThreeEmotionUni: [],
      topThreeProgUni: [],
      topThreeSocialsUni: [],
      topThreeEmotionUniDay: [],
      topThreeProgUniDay: [],
      topThreeSocialsUniDay: [],
      topThreeSocialsUniWeek: [],
      topThreeSocialsUniMonth: [],
      topThreeProgUniWeek: [],
      topThreeProgUniMonth: [],
      topThreeEmotionUniWeek: [],
      topThreeEmotionUniMonth: [],
      threeNotifications: [],
    };
  }

  componentDidMount() {
    NotificationService.getUserRequestsThree(this.state.currentUser.id).then(
      (response) => {
        this.setState({
          threeNotifications: response.data.data.intervention,
        });
      },
      (error) => {
        this.setState({
          threeNotifications:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });
      }
    );

    UserService.getUser(this.state.currentUser.id).then((response) => {
      const content = response.data.data.user.currentEmotion;

      this.setState({
        userDetails: content,
      });
    });

    ELPService.getEmotionList().then((response) => {
      const content = response.data.emotion;

      let emotionList = {};

      for (let i = 0; i < content.length; i++) {
        emotionList[content[i]._id] = content[i].emotion;
      }
      this.setState({
        emotionList: emotionList,
      });
    });

    ELPService.getAllEmotions().then((response) => {
      const content = response.data.emotions;

      this.setState({
        data: content,
      });
    });

    InterventionService.getTop3("all").then((response) => {
      const content = response.data.interventions;

      // console.log(content);

      this.setState({
        topThreeSocials: content,
      });
    });

    InterventionService.getTop3("day").then((response) => {
      const content = response.data.interventions;

      // console.log(content);

      this.setState({
        topThreeSocialsDay: content,
      });
    });

    InterventionService.getTop3("week").then((response) => {
      const content = response.data.interventions;

      // console.log(content);

      this.setState({
        topThreeSocialsWeek: content,
      });
    });
    InterventionService.getTop3("month").then((response) => {
      const content = response.data.interventions;

      // console.log(content);

      this.setState({
        topThreeSocialsMonth: content,
      });
    });

    ELPService.getTop3("all").then((response) => {
      const content = response.data.emotions;

      // console.log(content);

      this.setState({
        topThreeEmotion: content,
      });
    });

    ELPService.getTop3("week").then((response) => {
      const content = response.data.emotions;

      // console.log(content);

      this.setState({
        topThreeEmotionWeek: content,
      });
    });

    ELPService.getTop3("month").then((response) => {
      const content = response.data.emotions;

      // console.log(content);

      this.setState({
        topThreeEmotionMonth: content,
      });
    });

    ELPService.getTop3("day").then((response) => {
      const content = response.data.emotions;

      // console.log(content);

      this.setState({
        topThreeEmotionDay: content,
      });
    });

    ProgBehavService.getTop3("all").then((response) => {
      const content = response.data.progBehavs;

      // console.log(response);

      this.setState({
        topThreeProg: content,
      });
    });

    ProgBehavService.getTop3("week").then((response) => {
      const content = response.data.progBehavs;

      // console.log(response);

      this.setState({
        topThreeProgWeek: content,
      });
    });

    ProgBehavService.getTop3("month").then((response) => {
      const content = response.data.progBehavs;

      // console.log(response);

      this.setState({
        topThreeProgMonth: content,
      });
    });

    ProgBehavService.getTop3("day").then((response) => {
      const content = response.data.progBehavs;

      // console.log(response);

      this.setState({
        topThreeProgDay: content,
      });
    });

    ELPService.getTop3Uni("all").then((response) => {
      const content = response.data.emotions;

      // console.log(content);

      this.setState({
        topThreeEmotionUni: content,
      });
    });

    ELPService.getTop3Uni("week").then((response) => {
      const content = response.data.emotions;

      // console.log(content);

      this.setState({
        topThreeEmotionUniWeek: content,
      });
    });

    ELPService.getTop3Uni("month").then((response) => {
      const content = response.data.emotions;

      // console.log(content);

      this.setState({
        topThreeEmotionUniMonth: content,
      });
    });

    ELPService.getTop3Uni("day").then((response) => {
      const content = response.data.emotions;

      // console.log(content);

      this.setState({
        topThreeEmotionUniDay: content,
      });
    });

    ProgBehavService.getTop3Uni("all").then((response) => {
      const content = response.data.progBehavs;

      // console.log(content);

      this.setState({
        topThreeProgUni: content,
      });
    });

    ProgBehavService.getTop3Uni("week").then((response) => {
      const content = response.data.progBehavs;

      // console.log(content);

      this.setState({
        topThreeProgUniWeek: content,
      });
    });

    ProgBehavService.getTop3Uni("month").then((response) => {
      const content = response.data.progBehavs;

      // console.log(content);

      this.setState({
        topThreeProgUniMonth: content,
      });
    });

    ProgBehavService.getTop3Uni("day").then((response) => {
      const content = response.data.progBehavs;

      // console.log(content);

      this.setState({
        topThreeProgUniDay: content,
      });
    });

    InterventionService.getTop3Uni("all").then((response) => {
      const content = response.data.interventions;

      // console.log(content);

      this.setState({
        topThreeSocialsUni: content,
      });
    });

    InterventionService.getTop3Uni("week").then((response) => {
      const content = response.data.interventions;

      // console.log(content);

      this.setState({
        topThreeSocialsUniWeek: content,
      });
    });

    InterventionService.getTop3Uni("month").then((response) => {
      const content = response.data.interventions;

      // console.log(content);

      this.setState({
        topThreeSocialsUniMonth: content,
      });
    });

    InterventionService.getTop3Uni("day").then((response) => {
      const content = response.data.interventions;

      // console.log(content);

      this.setState({
        topThreeSocialsUniDay: content,
      });
    });
  }

  displayData(data) {
    if (data.length === 0) {
      return "There is none";
    } else {
      return data;
    }
  }

  newDateFormat(rawDate) {
    const formated_Date = rawDate;
    const date = new Date(formated_Date);
    let hours = date.getHours();

    const suffix = hours >= 12 ? "pm" : "am";

    hours = hours > 12 ? hours - 12 : hours;

    const fullTimestamp =
      date.getMonth() +
      1 +
      "/" +
      date.getDate() +
      "/" +
      date.getFullYear() +
      " " +
      hours +
      ":" +
      date.getMinutes() +
      " " +
      suffix;

    return fullTimestamp;
  }

  userRequestPhrase(intervention, username, data) {
    let phrase = "";
    if (intervention === "give-help-a") {
      phrase = username + " may need help!";
    } else if (intervention === "give-help-b") {
      phrase = "You can help " + username + "!";
    } else if (intervention === "ask-help-a") {
      phrase = "Ask " + username + " for help!";
    } else if (intervention === "ask-help-b") {
      phrase = "Ask someone for help!";
    } else if (intervention === "reflection") {
      let timeNowRaw = new Date(Date.now() - 86400 * 1000).toISOString();
      // let timeIntervention = moment(data.timestamp);
      let timeInterventionRaw = moment(data.timestamp);
      let timeNow = moment(timeNowRaw);
      // let timeSevenDays = timeIntervention.add(7, "days");

      let diffDays = timeNow.diff(timeInterventionRaw, "days");

      if (diffDays >= 7) {
        phrase = "Reflect on your recent interaction with " + username + "!";
      } else {
        phrase =
          "Reflect soon on your recent interaction with " + username + "!";
      }
    } else if (intervention === "give-thanks-a") {
      phrase = username + " says thanks! Say thank you.";
    } else if (intervention === "give-thanks-b") {
      phrase = "Say thank you to " + username + " !";
    } else if (intervention === "action-a") {
      phrase = username + " wants to connect! ";
    } else if (intervention === "receive-thanks") {
      phrase = username + " said thanks! ";
    } else if (intervention === "help-giving-c") {
      phrase = "Help someone who is struggling!";
    } else if (intervention === "self-report") {
      phrase = "You experienced the same error multiple times. Let's check in!";
    } else if (intervention === "empathy-nudge") {
      phrase = "Empathize with " + username;
    } else if (intervention === "help-giving-empathy") {
      phrase =
        "You empathized with " + username + "'s struggles. You can help!";
    } else {
      phrase = "Thank you for completing your reflection! ";
    }
    return phrase;
  }

  render() {
    let currentEmotion = this.state.emotionList[this.state.userDetails];
    let username = "";

    return (
      <div className="container">
        <div className="row">
          {" "}
          <h5> Recent Requests </h5>
          <div className="text-notif">
            {this.state.threeNotifications.map((item) => (
              <div className="request-item-notif">
                {this.newDateFormat(item.timestamp)}{" "}
                {item.InterventionType.map((cosub) => (
                  <span>
                    {item.userIdfrom.map((sub) => (
                      <span className="hidden-section">
                        {(username = sub.username)}
                      </span>
                    ))}

                    {this.userRequestPhrase(
                      cosub.interventionType,
                      username,
                      item
                    )}
                  </span>
                ))}
              </div>
            ))}
            <Link to={"/requests"} className="request-button">
              View All Requests
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-8">
            {/* <RadialChart
              data={[{ angle: 1 }, { angle: 5 }, { angle: 2 }]}
              width={300}
              height={300}
            /> */}
            <h2> Emotional Climate </h2>

            <div className="row">
              <div className="col-sm-4">
                <Card style={{ width: "18rem" }}>
                  <Card.Body>
                    <Card.Title>Top Reported Emotions </Card.Title>
                    <Card.Text>
                      <Tabs
                        defaultActiveKey="all"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                      >
                        <Tab
                          eventKey="all"
                          title="All Time"
                          tabClassName="sub-tab-small"
                        >
                          {" "}
                          <Tabs
                            defaultActiveKey="Total"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                          >
                            <Tab
                              eventKey="Total"
                              title="Total"
                              tabClassName="sub-tab-smaller"
                            >
                              {" "}
                              {this.state.topThreeEmotion.map((item) => (
                                <p>
                                  <span className="circle-stats">
                                    {" "}
                                    {item.count}{" "}
                                  </span>
                                  {item.emotionName}
                                </p>
                              ))}{" "}
                            </Tab>

                            <Tab
                              eventKey="Unique"
                              title="Unique"
                              tabClassName="sub-tab-smaller"
                            >
                              {" "}
                              {this.state.topThreeEmotionUni.map((item) => (
                                <p>
                                  <span className="circle-stats">
                                    {" "}
                                    {item.count}{" "}
                                  </span>
                                  {item.emotionName}
                                </p>
                              ))}{" "}
                            </Tab>
                          </Tabs>{" "}
                        </Tab>
                        <Tab
                          eventKey="day"
                          title="Day"
                          tabClassName="sub-tab-small"
                        >
                          <Tabs
                            defaultActiveKey="Total"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                          >
                            <Tab
                              eventKey="Total"
                              title="Total"
                              tabClassName="sub-tab-smaller"
                            >
                              {" "}
                              {this.state.topThreeEmotionDay.map((item) => (
                                <p>
                                  <span className="circle-stats">
                                    {" "}
                                    {item.count}{" "}
                                  </span>
                                  {item.emotionName}
                                </p>
                              ))}{" "}
                            </Tab>

                            <Tab
                              eventKey="Unique"
                              title="Unique"
                              tabClassName="sub-tab-smaller"
                            >
                              {" "}
                              {this.state.topThreeEmotionUniDay.map((item) => (
                                <p>
                                  <span className="circle-stats">
                                    {" "}
                                    {item.count}{" "}
                                  </span>
                                  {item.emotionName}
                                </p>
                              ))}{" "}
                            </Tab>
                          </Tabs>
                        </Tab>
                        <Tab
                          eventKey="week"
                          title="Week"
                          tabClassName="sub-tab-small"
                        >
                          {" "}
                          <Tabs
                            defaultActiveKey="Total"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                          >
                            <Tab
                              eventKey="Total"
                              title="Total"
                              tabClassName="sub-tab-smaller"
                            >
                              {this.state.topThreeEmotionWeek.map((item) => (
                                <p>
                                  <span className="circle-stats">
                                    {" "}
                                    {item.count}{" "}
                                  </span>
                                  {item.emotionName}
                                </p>
                              ))}{" "}
                            </Tab>

                            <Tab
                              eventKey="Unique"
                              title="Unique"
                              tabClassName="sub-tab-smaller"
                            >
                              {/* <p>
                                {this.displayData(
                                  this.state.topThreeEmotionUniWeek
                                )}
                              </p> */}{" "}
                              {this.state.topThreeEmotionUniWeek.map((item) => (
                                <p>
                                  <span className="circle-stats">
                                    {" "}
                                    {item.count}{" "}
                                  </span>
                                  {item.emotionName}
                                </p>
                              ))}{" "}
                            </Tab>
                          </Tabs>
                        </Tab>
                        <Tab
                          eventKey="month"
                          title="Month"
                          tabClassName="sub-tab-small"
                        >
                          {" "}
                          <Tabs
                            defaultActiveKey="Total"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                          >
                            <Tab
                              eventKey="Total"
                              title="Total"
                              tabClassName="sub-tab-smaller"
                            >
                              {this.state.topThreeEmotionMonth.map((item) => (
                                <p>
                                  <span className="circle-stats">
                                    {" "}
                                    {item.count}{" "}
                                  </span>
                                  {item.emotionName}
                                </p>
                              ))}{" "}
                            </Tab>

                            <Tab
                              eventKey="Unique"
                              title="Unique"
                              tabClassName="sub-tab-smaller"
                            >
                              {/* <p>
                                {this.displayData(
                                  this.state.topThreeEmotionUniWeek
                                )}
                              </p> */}{" "}
                              {this.state.topThreeEmotionUniMonth.map(
                                (item) => (
                                  <p>
                                    <span className="circle-stats">
                                      {" "}
                                      {item.count}{" "}
                                    </span>
                                    {item.emotionName}
                                  </p>
                                )
                              )}{" "}
                            </Tab>
                          </Tabs>
                        </Tab>
                      </Tabs>
                    </Card.Text>
                  </Card.Body>
                </Card>

                <Card style={{ width: "18rem" }}>
                  <Card.Body>
                    <Card.Title>Top Programming Activity </Card.Title>
                    <Card.Text>
                      <Tabs
                        defaultActiveKey="all"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                      >
                        <Tab
                          eventKey="all"
                          title="All Time"
                          tabClassName="sub-tab-small"
                        >
                          {" "}
                          <Tabs
                            defaultActiveKey="Total"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                          >
                            <Tab
                              eventKey="Total"
                              title="Total"
                              tabClassName="sub-tab-smaller"
                            >
                              {" "}
                              {this.state.topThreeProg.map((item) => (
                                <p>
                                  <span className="circle-stats">
                                    {" "}
                                    {item.count}{" "}
                                  </span>
                                  {item.progbehavType}
                                </p>
                              ))}{" "}
                            </Tab>

                            <Tab
                              eventKey="Unique"
                              title="Unique"
                              tabClassName="sub-tab-smaller"
                            >
                              {" "}
                              {this.state.topThreeProgUni.map((item) => (
                                <p>
                                  <span className="circle-stats">
                                    {" "}
                                    {item.count}{" "}
                                  </span>
                                  {item.progbehavType}
                                </p>
                              ))}{" "}
                            </Tab>
                          </Tabs>{" "}
                        </Tab>
                        <Tab
                          eventKey="day"
                          title="Day"
                          tabClassName="sub-tab-small"
                        >
                          <Tabs
                            defaultActiveKey="Total"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                          >
                            <Tab
                              eventKey="Total"
                              title="Total"
                              tabClassName="sub-tab-smaller"
                            >
                              {" "}
                              {this.state.topThreeProgDay.map((item) => (
                                <p>
                                  <span className="circle-stats">
                                    {" "}
                                    {item.count}{" "}
                                  </span>
                                  {item.progbehavType}
                                </p>
                              ))}{" "}
                            </Tab>

                            <Tab
                              eventKey="Unique"
                              title="Unique"
                              tabClassName="sub-tab-smaller"
                            >
                              {" "}
                              {this.state.topThreeProgUniDay.map((item) => (
                                <p>
                                  <span className="circle-stats">
                                    {" "}
                                    {item.count}{" "}
                                  </span>
                                  {item.progbehavType}
                                </p>
                              ))}{" "}
                            </Tab>
                          </Tabs>
                        </Tab>
                        <Tab
                          eventKey="week"
                          title="Week"
                          tabClassName="sub-tab-small"
                        >
                          {" "}
                          <Tabs
                            defaultActiveKey="Total"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                          >
                            <Tab
                              eventKey="Total"
                              title="Total"
                              tabClassName="sub-tab-smaller"
                            >
                              {" "}
                              {this.state.topThreeProgWeek.map((item) => (
                                <p>
                                  <span className="circle-stats">
                                    {" "}
                                    {item.count}{" "}
                                  </span>
                                  {item.progbehavType}
                                </p>
                              ))}{" "}
                            </Tab>

                            <Tab
                              eventKey="Unique"
                              title="Unique"
                              tabClassName="sub-tab-smaller"
                            >
                              {" "}
                              {this.state.topThreeProgUniWeek.map((item) => (
                                <p>
                                  <span className="circle-stats">
                                    {" "}
                                    {item.count}{" "}
                                  </span>
                                  {item.progbehavType}
                                </p>
                              ))}{" "}
                            </Tab>
                          </Tabs>
                        </Tab>
                        <Tab
                          eventKey="month"
                          title="Month"
                          tabClassName="sub-tab-small"
                        >
                          {" "}
                          <Tabs
                            defaultActiveKey="Total"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                          >
                            <Tab
                              eventKey="Total"
                              title="Total"
                              tabClassName="sub-tab-smaller"
                            >
                              {" "}
                              {this.state.topThreeProgMonth.map((item) => (
                                <p>
                                  <span className="circle-stats">
                                    {" "}
                                    {item.count}{" "}
                                  </span>
                                  {item.progbehavType}
                                </p>
                              ))}{" "}
                            </Tab>

                            <Tab
                              eventKey="Unique"
                              title="Unique"
                              tabClassName="sub-tab-smaller"
                            >
                              {" "}
                              {this.state.topThreeProgUniMonth.map((item) => (
                                <p>
                                  <span className="circle-stats">
                                    {" "}
                                    {item.count}{" "}
                                  </span>
                                  {item.progbehavType}
                                </p>
                              ))}{" "}
                            </Tab>
                          </Tabs>
                        </Tab>
                      </Tabs>
                    </Card.Text>
                  </Card.Body>
                </Card>

                <Card style={{ width: "18rem" }}>
                  <Card.Body>
                    <Card.Title>Top Social Activities </Card.Title>
                    <Card.Text>
                      <Tabs
                        defaultActiveKey="all"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                      >
                        <Tab
                          eventKey="all"
                          title="All Time"
                          tabClassName="sub-tab-small"
                        >
                          {" "}
                          <Tabs
                            defaultActiveKey="Total"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                          >
                            <Tab
                              eventKey="Total"
                              title="Total"
                              tabClassName="sub-tab-smaller"
                            >
                              {" "}
                              {this.state.topThreeSocials.map((item) => (
                                <p>
                                  <span className="circle-stats">
                                    {" "}
                                    {item.count}{" "}
                                  </span>
                                  {item.interventionType}
                                </p>
                              ))}{" "}
                            </Tab>

                            <Tab
                              eventKey="Unique"
                              title="Unique"
                              tabClassName="sub-tab-smaller"
                            >
                              {" "}
                              {this.state.topThreeSocialsUni.map((item) => (
                                <p>
                                  <span className="circle-stats">
                                    {" "}
                                    {item.count}{" "}
                                  </span>
                                  {item.interventionType}
                                </p>
                              ))}{" "}
                            </Tab>
                          </Tabs>{" "}
                        </Tab>
                        <Tab
                          eventKey="day"
                          title="Day"
                          tabClassName="sub-tab-small"
                        >
                          <Tabs
                            defaultActiveKey="Total"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                          >
                            <Tab
                              eventKey="Total"
                              title="Total"
                              tabClassName="sub-tab-smaller"
                            >
                              {" "}
                              {this.state.topThreeSocialsDay.map((item) => (
                                <p>
                                  <span className="circle-stats">
                                    {" "}
                                    {item.count}{" "}
                                  </span>
                                  {item.interventionType}
                                </p>
                              ))}{" "}
                            </Tab>

                            <Tab
                              eventKey="Unique"
                              title="Unique"
                              tabClassName="sub-tab-smaller"
                            >
                              {" "}
                              {this.state.topThreeSocialsUniDay.map((item) => (
                                <p>
                                  <span className="circle-stats">
                                    {" "}
                                    {item.count}{" "}
                                  </span>
                                  {item.interventionType}
                                </p>
                              ))}{" "}
                            </Tab>
                          </Tabs>
                        </Tab>
                        <Tab
                          eventKey="week"
                          title="Week"
                          tabClassName="sub-tab-small"
                        >
                          {" "}
                          <Tabs
                            defaultActiveKey="Total"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                          >
                            <Tab
                              eventKey="Total"
                              title="Total"
                              tabClassName="sub-tab-smaller"
                            >
                              {" "}
                              {this.state.topThreeSocialsWeek.map((item) => (
                                <p>
                                  <span className="circle-stats">
                                    {" "}
                                    {item.count}{" "}
                                  </span>
                                  {item.interventionType}
                                </p>
                              ))}{" "}
                            </Tab>

                            <Tab
                              eventKey="Unique"
                              title="Unique"
                              tabClassName="sub-tab-smaller"
                            >
                              {" "}
                              {this.state.topThreeSocialsUniWeek.map((item) => (
                                <p>
                                  <span className="circle-stats">
                                    {" "}
                                    {item.count}{" "}
                                  </span>
                                  {item.interventionType}
                                </p>
                              ))}{" "}
                            </Tab>
                          </Tabs>
                        </Tab>
                        <Tab
                          eventKey="month"
                          title="Month"
                          tabClassName="sub-tab-small"
                        >
                          {" "}
                          <Tabs
                            defaultActiveKey="Total"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                          >
                            <Tab
                              eventKey="Total"
                              title="Total"
                              tabClassName="sub-tab-smaller"
                            >
                              {" "}
                              {this.state.topThreeSocialsMonth.map((item) => (
                                <p>
                                  <span className="circle-stats">
                                    {" "}
                                    {item.count}{" "}
                                  </span>
                                  {item.interventionType}
                                </p>
                              ))}{" "}
                            </Tab>

                            <Tab
                              eventKey="Unique"
                              title="Unique"
                              tabClassName="sub-tab-smaller"
                            >
                              {" "}
                              {this.state.topThreeSocialsUniMonth.map(
                                (item) => (
                                  <p>
                                    <span className="circle-stats">
                                      {" "}
                                      {item.count}{" "}
                                    </span>
                                    {item.interventionType}
                                  </p>
                                )
                              )}{" "}
                            </Tab>
                          </Tabs>
                        </Tab>
                      </Tabs>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-sm-8">
                {" "}
                <h3>Your Current Emotion: {currentEmotion}</h3>
                <Card className="padding-margin">
                  <Card.Body>
                    <Tabs
                      defaultActiveKey="pie"
                      id="uncontrolled-tab-example"
                      className="mb-3"
                    >
                      <Tab
                        eventKey="pie"
                        title="Pie Chart"
                        tabClassName="sub-tab-small"
                      >
                        <Card.Title>Current Peer Emotional States</Card.Title>

                        <ELPVisualizationPanel></ELPVisualizationPanel>
                      </Tab>
                      {/* <Tab
                        eventKey="bar"
                        title="Bar Chart"
                        tabClassName="sub-tab-small"
                      >
                        <BarChartEmotions></BarChartEmotions>
                      </Tab> */}
                    </Tabs>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <h2>Activity Feed</h2>
            <ELPActivityFeed></ELPActivityFeed>
          </div>
        </div>
      </div>
    );
  }
}
