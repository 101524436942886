import axios from "axios";
import authHeader from "./auth-header";
const apiConfig = require("./../config/api.config");

// const API_URL = "https://helpd-empathy-tool.herokuapp.com/api/test/";
// const API_URL_USER = "https://helpd-empathy-tool.herokuapp.com/";

const API_URL = apiConfig.API_URL + "/api/test/";
const API_URL_USER = apiConfig.API_URL + "/api/";

class UserService {
  getPublicContent() {
    return axios.get(API_URL + "all");
  }

  getUserBoard() {
    return axios.get(API_URL + "user");
  }

  getModeratorBoard() {
    return axios.get(API_URL + "mod", { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + "admin", { headers: authHeader() });
  }

  getUserList() {
    return axios.get(API_URL + "getUsers");
  }

  getUser(id) {
    return axios.get(API_URL_USER + "user/" + id, {
      headers: authHeader(),
    });
  }

  updatePicture(id, profileImage) {
    return axios.post(API_URL_USER + id, { profileImage });
  }

  getUserProfileImg(id) {
    return axios.get(API_URL_USER + "profileImg/" + id);
  }

  updateUserEmotionStatus(userId, currentEmotion, persistEmotion) {
    return axios.post(API_URL_USER + "emotionUserUpdate/" + userId, {
      currentEmotion,
      persistEmotion,
    });
  }

  updateRole(userId, roles) {
    return axios.post(API_URL_USER + userId, {
      roles,
    });
  }

  deleteUser(userId) {
    return axios.delete(API_URL_USER + "deleteUser/" + userId, {
      userId,
    });
  }
}

export default new UserService();
