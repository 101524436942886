import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
// import AuthService from "../../../services/auth-service";
import HelpGivingForm from "./help-giving-interventionc-stepper/stepper-help-giving-c";
import InterServices from "../../../services/intervention-service";

export default class HelpGivingInterventionC extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: this.props.data,
      isOpen: false,
      interImg: "http://localhost:8080/public/Interventions/",
    };
    this.handleInterChange = this.handleInterChange.bind(this);
  }

  //openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  componentDidMount() {
    //InterServices.updateToRead(this.state.content._id);
  }
  handleInterChange(e) {
    // const target = event.target;
    // var message = target.value;
    e.preventDefault();

    this.setState({ isOpen: true });
    InterServices.updateToRead(this.state.content._id);
  }
  render() {
    return (
      <div>
        <Button className="btn-success" onClick={this.handleInterChange}>
          Pay it forward!
        </Button>
        {/* This is where you will change up what button and intervention will
        show! */}
        <Modal show={this.state.isOpen} onHide={this.closeModal}>
          <Modal.Header>
            <Modal.Title>
              {" "}
              <img
                src="https://publichelpdet.s3.us-west-2.amazonaws.com/help.png"
                alt="profile"
                className="profileImage-tiny"
              />
              Help someone!{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <HelpGivingForm data={this.state.content}></HelpGivingForm>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
