import React, { Component } from "react";
import ProgBehavService from "./../services/progbehav-service";
import AuthService from "./../services/auth-service";
import DeleteProg from "./user-admin/delete-progBehav-component";
import ProgBehavDetails from "./prog-behav-features-comparison/user-progBehav-component-visual";
import moment from "moment";

export default class BoardUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthService.getCurrentUser(),
      content: [],
    };
  }

  newDateFormat(rawDate) {
    var stillUtc = moment.utc(rawDate).toDate();

    const fullTimestamp = moment(stillUtc)
      .local()
      .format("MMMM Do YYYY, h:mm:ss a");
    return fullTimestamp;
  }

  componentDidMount() {
    ProgBehavService.getUserProgBehavs(this.state.currentUser.id).then(
      (response) => {
        this.setState({
          content: response.data.data.progBehavs,
        });
      },
      (error) => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });
      }
    );
  }

  render() {
    return (
      <div className="container">
        <h1>Your Programming Behavior</h1>

        <div className="row">
          {" "}
          <div className="col-sm-8 ">
            <div className="row">
              <div className="col-sm-4 tableEmotions">
                <h4>Programming Behavior</h4>{" "}
              </div>
              <div className="col-sm-4 tableEmotions">
                <h4>Collected At</h4>
              </div>
            </div>
            {this.state.content.map((item) => (
              <div className="row">
                <div className="col-sm-4">
                  {" "}
                  {item.progBehaviorType.map((sub) => (
                    <span>{sub.progbehavType}</span>
                  ))}
                </div>
                <div className="col-sm-4">
                  {this.newDateFormat(item.timestamp)}
                </div>
              </div>
            ))}
          </div>
          <div className="col-sm-4">
            <h3>Your Programming Behavior (Visualized)</h3>
            <ProgBehavDetails></ProgBehavDetails>
          </div>
        </div>
      </div>
    );
  }
}
