import React, { Component } from "react";
import { Button, Container } from "react-bootstrap";
import AuthService from "../../../../services/auth-service";
import interventionService from "../../../../services/intervention-service";
import NewInternConnect from "../../../messages/new-connection-message";

class Confirmation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthService.getCurrentUser(),
      content: this.props.data,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  saveAndContinue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  handleSubmit(e) {
    e.preventDefault();
    // interventionService
    //   .generateIntervention(
    //     this.state.content.userIdfrom[0]._id,
    //     this.state.currentUser.id,
    //     "6200a4dee2fb3d1b70593675",
    //     this.state.content.emoID[0]._id, //Figure this out tomorrow
    //     this.makeArray(
    //       this.props.inputValues.internal,
    //       this.props.inputValues.im,
    //       this.props.inputValues.email,
    //       this.props.inputValues.socia,
    //       this.props.inputValues.empathy
    //     )
    //   )
    //   .then(() => {
    //     window.location.reload();
    //   });
  }

  makeArray(internal, im, email, social) {
    let details = [];

    if (internal !== "") {
      details.push(internal);
    }

    if (im !== "") {
      details.push(im);
    }

    if (email !== "") {
      details.push(email);
    }

    if (social !== "") {
      details.push(social);
    }

    return details;
  }
  render() {
    const {
      inputValues: { internal, im, email, social },
    } = this.props;
    let username = this.state.content.userIdfrom[0].username;

    return (
      <Container>
        <h1>Confirm your Details</h1>
        <p>Confirm if the following details are correct.</p>
        <p>You are contacting {username} for help!</p>
        <p>
          You are letting {username} know that they can connect with you
          through...<br></br>
          <br></br> {internal} {im} {email} {social}
        </p>
        <h3>Connect with {username} now </h3>
        <NewInternConnect connect={this.state.content}></NewInternConnect>
        <Button variant="secondary" onClick={this.back}>
          Back
        </Button>{" "}
      </Container>
    );
  }
}

export default Confirmation;
