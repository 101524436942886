/* eslint-disable default-case */
import React, { Component } from "react";
import AuthService from "../../../services/auth-service";
import InterServices from "../../../services/intervention-service";
import { Form } from "react-bootstrap";

export class ReceiveThanks extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthService.getCurrentUser(),
      content: this.props.data,
      interventionResponse: [],
      interventionText: "",
    };

    // this.handleSubmit = this.handleSubmit.bind(this);

    // this.handleInputChange = this.handleInputChange.bind(this);

    // this.handleTextChange = this.handleTextChange.bind(this);
  }

  //   handleTextChange(event) {
  //     const target = event.target;
  //     var value = target.value;

  //     this.state.interventionText = value;

  //     console.log(this.state.interventionText);
  //   }

  //   handleInputChange(event) {
  //     const target = event.target;
  //     var value = target.value;

  //     if (target.checked) {
  //       this.state.interventionResponse[value] = value;
  //     } else {
  //       // this.state.interventionResponse.splice(value, 1);
  //       this.state.interventionResponse[value] = "unchecked";
  //     }

  //     console.log(this.state.interventionResponse);
  //     console.log(this.state.currentUser.id);
  //     console.log(this.state.content);
  //   }

  //   makeArray(list, textarea) {
  //     let details = [];

  //     for (const [key, value] of Object.entries(list)) {
  //       if (value !== "unchecked") {
  //         details.push(key);
  //       }
  //     }

  //     details.push(textarea);

  //     console.log(details);
  //     return details;
  //   }

  //   handleSubmit(e) {
  //     e.preventDefault();

  //     InterServices.generateIntervention(
  //       this.state.currentUser.id,
  //       this.state.currentUser.id,
  //       "62089d2688126d48988438e0",
  //       this.state.content.emoID[0]._id,
  //       this.makeArray(
  //         this.state.interventionResponse,
  //         this.state.interventionText
  //       )
  //     ).then(() => {
  //       window.location.reload();
  //     });
  //   }

  render() {
    let username = this.state.content.userIdfrom[0].username;

    //Populate via database
    let itemsDetails = this.state.content.details;

    //console.log(itemsDetails);

    return (
      <div>
        {" "}
        <ul>
          {itemsDetails.map((item) => (
            <li> {item}</li>
          ))}
        </ul>
      </div>
    );
  }
}

export default ReceiveThanks;
