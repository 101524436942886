import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import AuthService from "../../../services/auth-service";
import HelpGivingForm from "./help-giving-empathy-intervention-stepper/stepper-help-give-empathy";
import InterServices from "../../../services/intervention-service";
import ELPUserProfile from "../../features/elp-activity-feed-indiv-profile-img";

export default class HelpGivingEmpathy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthService.getCurrentUser(),
      content: this.props.data,
      isOpen: false,
      imgState: "http://localhost:8080/public/UserProfiles/",
      interImg: "http://localhost:8080/public/Interventions/",
    };
    this.handleInterChange = this.handleInterChange.bind(this);
  }

  //openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  componentDidMount() {
    //   InterServices.updateToRead(this.state.content._id);
  }
  handleInterChange(e) {
    // const target = event.target;
    // var message = target.value;
    e.preventDefault();

    this.setState({ isOpen: true });
    InterServices.updateToRead(this.state.content._id);
  }

  render() {
    let username = this.state.content.userIdfrom[0].username;
    let profileImg = this.state.content.userIdfrom[0].profileImage;

    return (
      <div>
        {" "}
        <img
          src={this.state.imgState + profileImg}
          alt="profile"
          className="profileImage-tiny"
        />{" "}
        <Button className="btn-success" onClick={this.handleInterChange}>
          Help {username}
        </Button>
        {/* This is where you will change up what button and intervention will
        show! */}
        <Modal show={this.state.isOpen} onHide={this.closeModal}>
          <Modal.Header>
            <Modal.Title>
              {" "}
              <img
                src="https://publichelpdet.s3.us-west-2.amazonaws.com/help.png"
                alt="profile"
                className="profileImage-tiny"
              />
              {/* <img
                src={this.state.imgState + profileImg}
                alt="profile"
                className="profileImage-tiny"
              /> */}
              <ELPUserProfile
                id={this.state.content.userIdfrom[0]._id}
              ></ELPUserProfile>
              <span className="inter-text"> Help {username} </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <HelpGivingForm data={this.state.content}></HelpGivingForm>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
