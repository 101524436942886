import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { EnvelopeFill, KanbanFill } from "react-bootstrap-icons";
import { Form } from "react-bootstrap";
import UserService from "./../../services/user-service";
import AuthService from "./../../services/auth-service";
import MessageService from "./../../services/message-service";
import InterventionService from "../../services/intervention-service";

// import HelpGivingForm from "./help-giving-intervention-stepper/stepper-help-give";

export default class ReplyMessage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthService.getCurrentUser(),
      content: this.props.connect,
      //   messageContent: this.props.prevMess,
      isOpen: false,
      imgState: "http://localhost:8080/public/UserProfiles/",
      interImg: "http://localhost:8080/public/Interventions/",
      users: [],
      suggestions: [],
      text: "",
      subject: "",
      recipient: "",
      message: "",
      userData: [],
      sendTo: "",
    };
    // this.handleRecipientChange = this.handleRecipientChange.bind(this);
    this.handleSubjectChange = this.handleSubjectChange.bind(this);
    this.handleMessageChange = this.handleMessageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    // this.onTextChanged = this.onTextChanged.bind(this);
  }
  newDateFormat(rawDate) {
    const formated_Date = rawDate;
    const date = new Date(formated_Date); // formated_Date - SDK returned date
    let hours = date.getHours();

    const suffix = hours >= 12 ? "pm" : "am";

    hours = hours > 12 ? hours - 12 : hours;

    const fullTimestamp =
      date.getMonth() +
      "/" +
      date.getDate() +
      "/" +
      date.getFullYear() +
      " " +
      hours +
      ":" +
      date.getMinutes() +
      " " +
      suffix;

    return fullTimestamp;
  }

  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  handleSubjectChange(event) {
    const target = event.target;
    var subject = target.value;

    // this.state.subject = value;
    this.setState({ subject });
  }

  handleMessageChange(event) {
    const target = event.target;
    var message = target.value;
    this.setState({ message });
  }

  componentDidMount() {}

  handleSubmit(e) {
    e.preventDefault();

    // console.log(this.state.message);
    // console.log();

    MessageService.newMessage(
      this.state.content.userIdfrom[0]._id,
      this.state.currentUser.id,
      this.state.message + "\n",
      "Re: " + this.state.content.subLine
    );

    MessageService.newMessageSendGrid(
      this.state.content.userIdfrom[0]._id,
      "carla.delira@wsu.edu",
      "hELPd Empathy Tool Message Notification -- " +
        "Re: " +
        this.state.content.subLine,
      this.state.message + " -- Message Notification via hELPd Empathy Tool"
    );

    window.location.reload();
  }

  render() {
    return (
      <div>
        <Button
          className="btn-default"
          variant="primary"
          onClick={this.openModal}
        >
          <EnvelopeFill></EnvelopeFill> Reply to{" "}
          {this.state.content.userIdfrom[0].username}
        </Button>
        <Modal show={this.state.isOpen} onHide={this.closeModal}>
          <Modal.Header>
            <Modal.Title>
              <EnvelopeFill></EnvelopeFill> New Message
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={this.handleSubmit}>
              <Form.Label>
                Recipient: {this.state.content.userIdfrom[0].username}{" "}
              </Form.Label>

              <br></br>
              <Form.Label>Subject Line: </Form.Label>

              <Form.Control
                as="textarea"
                rows={1}
                onChange={this.handleSubjectChange}
                placeholder="Let's connect! I would like your help!"
                defaultValue={"Re: " + this.state.content.subLine}
              />
              <Form.Label>Message: </Form.Label>

              <Form.Control
                as="textarea"
                rows={3}
                onChange={this.handleMessageChange}
                defaultValue={
                  "\n----------Replying---------------\n" +
                  this.state.content.message +
                  this.state.content._id
                }
              />

              {/* <button className="btn btn-primary" type="submit"> */}
              <button className="btn btn-primary" onClick={this.handleSubmit}>
                Send
              </button>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
