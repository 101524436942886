import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import AuthService from "./../../services/auth-service";
import NewInterConnect from "./../messages/new-connection-af-message-component";
import ProgBehavService from "../../services/progbehav-service";

export default class EmotionDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthService.getCurrentUser(),
      content: this.props.data,
      progBehavTypeList: [],
      isOpen: false,
      imgState: "http://localhost:8080/public/UserProfiles/",
    };
  }

  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  componentDidMount() {
    ProgBehavService.getProgTypeList().then((response) => {
      const progBehavTypeList = response.data.progbehav;

      let dictProg = {};

      for (let i = 0; i < progBehavTypeList.length; i++) {
        dictProg[progBehavTypeList[i]._id] = progBehavTypeList[i].progbehavType;
      }

      // console.log(dictProg);

      this.setState({
        progBehavTypeList: dictProg,
      });
    });
  }

  render() {
    let username = this.state.content.userId[0].username;
    let emotion = this.state.content.emotStatus[0].emotion;
    let details = this.state.content.details;
    let profileImg = this.state.content.userId[0].profileImage;
    let emotionContext = this.state.content.emotContext;

    // console.log("test: ");
    // console.log();

    return (
      <div>
        <Button className="btn-default" onClick={this.openModal}>
          More details
        </Button>

        <Modal show={this.state.isOpen} onHide={this.closeModal}>
          <Modal.Header>
            <Modal.Title>
              {" "}
              {username} is feeling {emotion}.
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              {/* <img
                src={this.state.imgState + profileImg}
                alt="profile"
                className="profileImage-med"
              />{" "} */}
              <br></br>
              {username} is feeling {emotion}. They experienced a{" "}
              {this.state.progBehavTypeList[emotionContext]}.<br></br>
              <br></br>
              <i>{details}</i>
              <br></br>
              <br></br>
              Here are some ways to connect with {username}!
              <NewInterConnect connect={this.state.content}></NewInterConnect>
            </p>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
