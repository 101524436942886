import axios from "axios";
import authHeader from "./auth-header";
const apiConfig = require("./../config/api.config");

// const API_URL = "https://helpd-empathy-tool.herokuapp.com/api/";
// const URL = "https://helpd-empathy-tool.herokuapp.com/";
// const TYPE = "https://helpd-empathy-tool.herokuapp.com/db/";

const API_URL = apiConfig.API_URL + "/api/";
const URL = apiConfig.API_URL + "/api/";
const TYPE = apiConfig.API_URL + "/api/";

class ProgBehavService {
  getUserProgBehavs(userId) {
    return axios.get(API_URL + "getUserProgsBehavs/" + userId, {
      headers: authHeader(),
    });
  }

  getTop3(range) {
    return axios.get(URL + "prog/top3/" + range, {
      headers: authHeader(),
    });
  }

  getTop3Uni(range) {
    return axios.get(URL + "top3ProgUni/" + range, {
      headers: authHeader(),
    });
  }

  getProgTypeList() {
    return axios.get(TYPE + "getProgBehavList", {
      headers: authHeader(),
    });
  }

  getProgBehav(id) {
    return axios.get(TYPE + "getProgBehavType/" + id, {
      headers: authHeader(),
    });
  }

  deleteProgBehav(id) {
    return axios.delete(
      URL + "removeProgBehavs/" + id,
      {
        id,
      },
      {
        headers: authHeader(),
      }
    );
  }

  countProgBehav(range) {
    return axios.get(URL + "filterTimeDateProgBehav/" + range, {
      headers: authHeader(),
    });
  }

  getUserProgBehCount(id, time) {
    return axios.get(URL + "getProgBehavCount/" + id + "/" + time, {
      headers: authHeader(),
    });
  }
}

export default new ProgBehavService();
