import React, { Component } from "react";
import { Button, Container } from "react-bootstrap";
import AuthService from "../../../../services/auth-service";
import interventionService from "../../../../services/intervention-service";
import UserService from "../../../../services/user-service";

class Confirmation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthService.getCurrentUser(),
      content: this.props.data,
      chosenUser: "",
      userList: {},
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  saveAndContinue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  handleSubmit(e) {
    e.preventDefault();
    interventionService
      .generateIntervention(
        this.props.inputValues.chosenUser,
        this.state.currentUser.id,
        "6434e00036a83600143f9014",
        this.makeArray(
          this.props.inputValues.understand,
          this.props.inputValues.collaborate,
          this.props.inputValues.tutoring,
          this.props.inputValues.similar,
          this.props.inputValues.empathy
        )
      )
      .then(() => {
        window.location.reload();
      });
  }

  makeArray(understand, collaborate, tutoring, similar, empathy) {
    let details = [];

    if (understand !== "") {
      details.push(understand);
    }

    if (collaborate !== "") {
      details.push(collaborate);
    }

    if (tutoring !== "") {
      details.push(tutoring);
    }

    if (similar !== "") {
      details.push(similar);
    }

    if (empathy !== "") {
      details.push(empathy);
    }
    return details;
  }
  componentDidMount() {
    UserService.getUserList().then(
      (response) => {
        let userList = response.data.users;

        var dict = {};

        for (let i = 0; i < userList.length; i++) {
          console.log(userList[i]._id);

          dict[userList[i]._id] = userList[i].username;
        }

        userList = dict;

        this.setState({
          userList,
        });

        // console.log(this.state.userlist);
        console.log(userList);
      },
      (error) => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });
      }
    );
  }
  render() {
    const {
      inputValues: {
        chosenUser,
        understand,
        collaborate,
        tutoring,
        similar,
        empathy,
      },
    } = this.props;

    let username = this.state.userList[chosenUser];

    return (
      <Container>
        <img
          src={
            "https://publichelpdet.s3.us-west-2.amazonaws.com/check-mark.png"
          }
          alt="profile"
          className="profileImage-inter"
        />
        <h3 className="center-text">Confirm your Details</h3>
        <p>
          {" "}
          <strong>Confirm if the following details are correct.</strong>
        </p>
        <p>You are contacting {username} for giving help!</p>
        <p>
          You are letting {username} know that...<br></br>
          <br></br> {understand} {similar} {empathy}
          <br></br>
          <br></br>
          You are providing {username} help...
          <br></br>
          <br></br>
          {collaborate} {tutoring}
        </p>
        <Button variant="secondary" onClick={this.back}>
          Back
        </Button>{" "}
        <Button variant="success" onClick={this.handleSubmit}>
          Submit
        </Button>
      </Container>
    );
  }
}

export default Confirmation;
