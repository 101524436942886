import React, { Component } from "react";
import AuthService from "../../services/auth-service";
import UserService from "../../services/user-service";
import axios from "axios";

import { useState } from "react";

export default function NewPost() {
  const [file, setFile] = useState();

  const submit = async (event) => {
    event.preventDefault();

    console.log(AuthService.getCurrentUser().id);
    const formData = new FormData();
    formData.append("image", file);
    await axios
      .post(
        "https://helpd-empathy-tool.herokuapp.com/upload/" +
          AuthService.getCurrentUser().id,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then(() => {
        window.location.reload();
      });
  };

  return (
    <form onSubmit={submit}>
      <input
        onChange={(e) => setFile(e.target.files[0])}
        type="file"
        accept="image/*"
      ></input>
      <button type="submit">Submit</button>
    </form>
  );
}

// import React, { Component } from "react";
// import AuthService from "../../services/auth-service";
// import UserService from "../../services/user-service";
// import axios from "axios";

// export default class PictureUpload extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       selectedFile: null,
//       loaded: 0,
//       currentUser: AuthService.getCurrentUser(),
//     };
//   }

//   onChangeHandler = (event) => {
//     this.setState({
//       selectedFile: event.target.files[0],
//       loaded: 0,
//     });
//   };

//   onClickHandler = () => {
//     const data = new FormData();
//     const fileName = this.state.selectedFile.name.split(".");
//     const ext = fileName[fileName.length - 1];
//     console.log(ext);
//     data.append(
//       "file",
//       this.state.selectedFile,
//       this.state.currentUser.id + "-profilePicture." + ext
//     );

//     UserService.updatePicture(
//       this.state.currentUser.id,
//       this.state.currentUser.id + "-profilePicture." + ext
//     );

//     axios
//       .post("http://localhost:8080/upload", data, {
//         onUploadProgress: (ProgressEvent) => {
//           this.setState({
//             loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100,
//           });
//         },
//       })
//       .then((res) => {
//         console.log(res.statusText);
//         window.location.reload();
//       })
//       .catch((err) => {
//         console.log(err.statusText);
//       });
//   };

//   render() {
//     return (
//       <div className="container">
//         {" "}
//         <input type="file" name="file" onChange={this.onChangeHandler} />
//         <button
//           type="button"
//           class="btn btn-success btn-block"
//           onClick={this.onClickHandler}
//         >
//           Upload
//         </button>
//       </div>
//     );
//   }
// }
