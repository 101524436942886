import axios from "axios";
import authHeader from "./auth-header";
const apiConfig = require("./../config/api.config");

// const API_URL = "https://helpd-empathy-tool.herokuapp.com/";

const API_URL = apiConfig.API_URL + "/api/";

class MessageService {
  newMessage(userIdTo, userIdfrom, message, subLine) {
    return axios.post(
      API_URL + "newMessage",
      {
        userIdTo,
        userIdfrom,
        message,
        subLine,
      },
      { headers: authHeader() }
    );
  }

  newMessageSendGrid(to, from, subject, text) {
    return axios.post(
      API_URL + "send-email",
      {
        to,
        from,
        subject,
        text,
      },
      { headers: authHeader() }
    );
  }

  inbox(userIdTo) {
    return axios.get(
      API_URL + "inbox/" + userIdTo,
      {
        headers: authHeader(),
      },
      { headers: authHeader() }
    );
  }

  outbox(userIdfrom) {
    return axios.get(API_URL + "outbox/" + userIdfrom, {
      headers: authHeader(),
    });
  }

  deleteMessage(id) {
    return (
      axios.delete(API_URL + "deleteMessage/" + id), { headers: authHeader() }
    );
  }

  updateMessage(id) {
    return axios.post(API_URL + "updateMessage/" + id);
  }

  getNewMessages(userId) {
    return axios.get(API_URL + "getNewMessages/" + userId, {
      headers: authHeader(),
    });
  }
}

export default new MessageService();
