import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import AuthService from "./../../services/auth-service";
import { ArrowLeftRight } from "react-bootstrap-icons";
import ELPUserProfile from "./../features/elp-activity-feed-indiv-profile-img";
import ELPUserProfileMed from "./../features/elp-activity-feed-indiv-profile-img-md";

export default class HelpSeekingIntervention extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthService.getCurrentUser(),
      content: this.props.data,
      isOpen: false,
      imgState: "http://localhost:8080/public/UserProfiles/",
    };
  }

  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  componentDidMount() {}

  render() {
    let username = this.state.content.userIdfrom[0].username;
    let usernameTo = this.state.content.userIdTo[0].username;
    let profileImg = this.state.content.userIdfrom[0].profileImage;
    let profileImgTo = this.state.content.userIdTo[0].profileImage;

    //console.log("test: ");
    //console.log(usernameTo);

    return (
      <div>
        <ELPUserProfile
          id={this.state.content.userIdfrom[0]._id}
        ></ELPUserProfile>
        <ArrowLeftRight></ArrowLeftRight>
        <ELPUserProfile
          id={this.state.content.userIdTo[0]._id}
        ></ELPUserProfile>
        <Button className="btn-default" onClick={this.openModal}>
          More Details
        </Button>
        <Modal show={this.state.isOpen} onHide={this.closeModal}>
          <Modal.Header>
            <Modal.Title>
              {" "}
              {username}, here are the details of your request!{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              You sent a request to give help to{" "}
              {this.state.content.userIdTo[0].username}.<br></br>
              <ELPUserProfileMed
                id={this.state.content.userIdfrom[0]._id}
              ></ELPUserProfileMed>
              <ArrowLeftRight></ArrowLeftRight>
              <ELPUserProfileMed
                id={this.state.content.userIdTo[0]._id}
              ></ELPUserProfileMed>
              {this.state.content.details.map((sub) => (
                <li>{sub} </li>
              ))}
            </p>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
