import React, { Component } from "react";
import { Button, Dropdown, ButtonGroup } from "react-bootstrap";
import NotificationService from "./../services/notification-service";
import AuthService from "./../services/auth-service";

import HelpGivingIntervention from "./../components/intervention-features/help-giving-interventions/help-giving-intervention";
import HelpGivingInterventionB from "./../components/intervention-features/help-giving-interventions/help-giving-b-intervention";
import HelpGivingInterventionC from "./../components/intervention-features/help-giving-interventions/help-giving-c-intervention";
import HelpSeekingIntervention from "./intervention-features/ask-help-interventions/help-seeking-intervention";
import HelpSeekingInterventionB from "./intervention-features/ask-help-interventions/help-seeking-intervention-b";
import GiveThanks from "./intervention-features/gratitude-interventions/express-thanks";
import ReflectionIntervention from "./intervention-features/reflection-interventions/reflection";
import ActionA from "./intervention-features/actions-interventions/action-a";
import ReceiveThanks from "./intervention-features/gratitude-interventions/receive-thanks";
import ExpressThanksB from "./intervention-features/gratitude-interventions/express-thanks-b";
import EmpathyNudge from "./intervention-features/empathy-nudges-interventions/empathy-nudge-intervention";
import SelfReport from "./features/elp-self-report-component";
import HelpGivingInterventionEmpathy from "./../components/intervention-features/help-giving-interventions/help-giving-empathy-intervention";
import moment from "moment";

// import notificationForm from "./notification-form";
import { BellFill } from "react-bootstrap-icons";

export default class Notifications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthService.getCurrentUser(),
      content: [],
      isOpen: false,
      new: 0,
    };
  }

  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  componentDidMount() {
    NotificationService.getNewUserRequests(this.state.currentUser.id).then(
      (response) => {
        console.log(response.data.data.intervention.length);
        this.setState({
          content: response.data.data.intervention,
          new: response.data.data.intervention.length,
        });
      },
      (error) => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });
      }
    );
  }

  newDateFormat(rawDate) {
    var stillUtc = moment.utc(rawDate).toDate();

    const fullTimestamp = moment(stillUtc)
      .local()
      .format("MMMM Do YYYY, h:mm:ss a");
    return fullTimestamp;
  }

  userRequestPhrase(intervention, username) {
    let phrase = "";
    if (intervention === "give-help-a") {
      phrase = username + " may need help!";
    } else if (intervention === "give-help-b") {
      phrase = "You can help " + username + "!";
    } else if (intervention === "ask-help-a") {
      phrase = "Ask " + username + " for help!";
    } else if (intervention === "ask-help-b") {
      phrase = "Ask someone for help!";
    } else if (intervention === "reflection") {
      phrase = "Reflect on your recent interaction with " + username + "!";
    } else if (intervention === "give-thanks-a") {
      phrase = username + " says thanks! Say thank you.";
    } else if (intervention === "give-thanks-b") {
      phrase = "Say thank you to " + username + " !";
    } else if (intervention === "action-a") {
      phrase = username + " wants to connect! ";
    } else if (intervention === "receive-thanks") {
      phrase = username + " said thanks! ";
    } else if (intervention === "help-giving-c") {
      phrase = "Help someone who is struggling!";
    } else if (intervention === "self-report") {
      phrase = "Let's check in!";
    } else if (intervention === "empathy-nudge") {
      phrase = "Empathize with " + username;
    } else if (intervention === "help-giving-empathy") {
      phrase =
        "You empathized with " + username + "'s struggles. You can help!";
    } else {
      phrase = "null";
    }
    return phrase;
  }

  deliverInterventionComponent(intervention, data) {
    if (intervention === "give-help-a") {
      return <HelpGivingIntervention data={data}></HelpGivingIntervention>;
    } else if (intervention === "ask-help-a") {
      return <HelpSeekingIntervention data={data}></HelpSeekingIntervention>;
    } else if (intervention === "ask-help-b") {
      return <HelpSeekingInterventionB data={data}></HelpSeekingInterventionB>;
    } else if (intervention === "reflection") {
      return <ReflectionIntervention data={data}></ReflectionIntervention>;
    } else if (intervention === "give-thanks-a") {
      return <GiveThanks data={data}></GiveThanks>;
    } else if (intervention === "give-thanks-b") {
      return <ExpressThanksB data={data}></ExpressThanksB>;
    } else if (intervention === "action-a") {
      return <ActionA data={data}></ActionA>;
    } else if (intervention === "give-help-b") {
      return <HelpGivingInterventionB data={data}></HelpGivingInterventionB>;
    } else if (intervention === "receive-thanks") {
      return <ReceiveThanks data={data}></ReceiveThanks>;
    } else if (intervention === "help-giving-c") {
      return <HelpGivingInterventionC data={data}></HelpGivingInterventionC>;
    } else if (intervention === "self-report") {
      return <SelfReport></SelfReport>;
    } else if (intervention === "empathy-nudge") {
      return <EmpathyNudge data={data}></EmpathyNudge>;
    } else if (intervention === "help-giving-empathy") {
      return (
        <HelpGivingInterventionEmpathy
          data={data}
        ></HelpGivingInterventionEmpathy>
      );
    } else {
      return "No intervention found! ";
    }
  }

  render() {
    let username = "";
    let cssNotif = this.colorOfNotif;
    console.log(cssNotif);
    return (
      <div>
        {/* <Button className="btn-default"></Button> */}

        <Dropdown className="margining btn-sm btn" as={ButtonGroup}>
          <Button href="/requests" variant="success">
            <BellFill></BellFill>{" "}
            <span className="circle-notif-red">{this.state.new}</span>
          </Button>

          <Dropdown.Toggle split variant="success" id="dropdown-split-basic" />

          <Dropdown.Menu>
            {this.state.content.map((item) => (
              <Dropdown.Item href="#/action-1">
                {this.newDateFormat(item.timestamp)} |{" "}
                {item.InterventionType.map((cosub) => (
                  <span>
                    {item.userIdfrom.map((sub) => (
                      <span className="hidden-section">
                        {(username = sub.username)}
                      </span>
                    ))}
                    {this.userRequestPhrase(cosub.interventionType, username)} |{" "}
                    {this.deliverInterventionComponent(
                      cosub.interventionType,
                      item
                    )}
                  </span>
                ))}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
}
