import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
// import AuthService from "../../../services/auth-service";
// import HelpGivingForm from "./help-giving-intervention-stepper/stepper-help-give";
import ELPUserProfile from "./../features/elp-activity-feed-indiv-profile-img";
import moment from "moment";

export default class SentMessage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      //   currentUser: AuthService.getCurrentUser(),
      content: this.props.data,
      isOpen: false,
      imgState: "http://localhost:8080/public/UserProfiles/",
      interImg: "http://localhost:8080/public/Interventions/",
    };
  }

  newDateFormat(rawDate) {
    var stillUtc = moment.utc(rawDate).toDate();

    const fullTimestamp = moment(stillUtc)
      .local()
      .format("MMMM Do YYYY, h:mm:ss a");
    return fullTimestamp;
  }

  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  componentDidMount() {}

  render() {
    return (
      <div>
        <Button className="" variant="link" onClick={this.openModal}>
          {this.state.content.subLine}
        </Button>
        <Modal show={this.state.isOpen} onHide={this.closeModal}>
          <Modal.Header>
            <Modal.Title>
              {/* <img
                src={
                  this.state.imgState +
                  this.state.content.userIdTo[0].profileImage
                }
                alt="profile"
                className="profileImage-tiny"
              /> */}
              <ELPUserProfile
                id={this.state.content.userIdTo[0]._id}
              ></ELPUserProfile>
              <span className="inter-text">
                {this.state.content.subLine} | Sent by you
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.newDateFormat(this.state.content.timestamp)}
            <br></br>
            <br></br> {this.state.content.message}
            <br></br>
            <br></br>
            {/* <Button className="btn-danger">Delete</Button> */}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
