import axios from "axios";
import authHeader from "./auth-header";
const apiConfig = require("./../config/api.config");

const API_URL = apiConfig.API_URL + "/api/";
const URL = apiConfig.API_URL + "/api/";
const API_URL_DB = apiConfig.API_URL + "/api/";

class ELPService {
  getAllEmotions() {
    return axios.get(API_URL + "allEmotions", { headers: authHeader() });
  }

  getActivityFeed() {
    return axios.get(API_URL + "activityFeed", { headers: authHeader() });
  }

  getEmotionList() {
    return axios.get(API_URL_DB + "getEmotionList", { headers: authHeader() });
  }

  getChartInfo() {
    return axios.get(API_URL + "chartEmotions", { headers: authHeader() });
  }

  createEmotions(userId, emotStatus, emotContext, details) {
    return axios.post(
      API_URL + "reportEmotion",
      {
        userId,
        emotStatus,
        emotContext,
        details,
      },
      { headers: authHeader() }
    );
  }

  getUserEmotions(userId) {
    return axios.get(API_URL + "yourEmotions/" + userId, {
      headers: authHeader(),
    });
  }

  getLatest() {
    return axios.get(API_URL + "latest/", { headers: authHeader() });
  }

  getTop3(range) {
    return axios.get(URL + "top3/" + range, { headers: authHeader() });
  }

  getTop3Uni(range) {
    return axios.get(URL + "top3Uni/" + range, { headers: authHeader() });
  }

  filterEmotions(emotion) {
    return axios.get(API_URL + "filterEmotions/" + emotion, {
      headers: authHeader(),
    });
  }

  getUserEmotionsTime(userId, range) {
    return axios.get(API_URL + "yourEmotionsTime/" + userId + "/" + range, {
      headers: authHeader(),
    });
  }

  countEmotionsByCount(range) {
    return axios.get(API_URL + "filterTimeDateEmotion/" + range, {
      headers: authHeader(),
    });
  }

  getEmotionContext(range) {
    return axios.get(URL + "emotContextFreq/" + range, {
      headers: authHeader(),
    });
  }

  getEmotionContextUni(range) {
    return axios.get(URL + "emotContextFreqUni/" + range, {
      headers: authHeader(),
    });
  }

  getTotalEmotions(range) {
    return axios.get(URL + "EmotionsTotal/" + range, { headers: authHeader() });
  }

  getUniqueEmotion(range) {
    return axios.get(URL + "EmotionsUnique/" + range, {
      headers: authHeader(),
    });
  }

  getSingleEmotionContext(emoType) {
    return axios.get(URL + "contextSingEmo/" + emoType, {
      headers: authHeader(),
    });
  }

  deleteEmotionAll(emoId) {
    return axios.delete(
      API_URL + "deleteEmoAll/" + emoId,
      {
        emoId,
      },
      { headers: authHeader() }
    );
  }
}

export default new ELPService();
