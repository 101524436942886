/* eslint-disable default-case */
import React, { Component } from "react";
import AuthService from "../../../services/auth-service";
import InterServices from "../../../services/intervention-service";
import { Form } from "react-bootstrap";

export class ExpressThanksForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthService.getCurrentUser(),
      content: this.props.data,
      interventionResponse: [],
      interventionText: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);

    this.handleInputChange = this.handleInputChange.bind(this);

    this.handleTextChange = this.handleTextChange.bind(this);
  }

  handleTextChange(event) {
    const target = event.target;
    var value = target.value;

    this.state.interventionText = value;

    //console.log(this.state.interventionText);
  }

  handleInputChange(event) {
    const target = event.target;
    var value = target.value;

    if (target.checked) {
      this.state.interventionResponse[value] = value;
    } else {
      // this.state.interventionResponse.splice(value, 1);
      this.state.interventionResponse[value] = "unchecked";
    }

    //console.log(this.state.interventionResponse);
    //console.log(this.state.currentUser.id);
    //console.log(this.state.content);
  }

  makeArray(list, textarea) {
    let details = [];

    for (const [key, value] of Object.entries(list)) {
      if (value !== "unchecked") {
        details.push(key);
      }
    }

    details.push(textarea);

    //console.log(details);
    return details;
  }

  handleSubmit(e) {
    e.preventDefault();

    InterServices.generateIntervention(
      this.state.content.userIdfrom[0]._id,
      this.state.currentUser.id,
      "6434e01f36a83600143f901a",
      // this.state.content.emoID[0]._id,
      this.makeArray(
        this.state.interventionResponse,
        this.state.interventionText
      )
    );

    InterServices.generateReflection(
      this.state.currentUser.id,
      this.state.content.userIdfrom[0]._id,
      "6434dfd436a83600143f900c",
      true
    );

    window.location.reload();
  }

  render() {
    let username = this.state.content.userIdfrom[0].username;

    //Populate via database
    const itemsThanks = [
      "Helping me with learning how to code",
      "Reaching out to help me with code",
      "Connecting for support",
      "Tutoring me",
    ];

    const itemDetails = this.state.content.details;

    //console.log(itemDetails);
    return (
      <div>
        <p> {username} says thanks...</p>
        <ul>
          {itemDetails.map((item) => (
            <li>{item}</li>
          ))}
        </ul>

        <Form onSubmit={this.handleSubmit}>
          <Form.Label>
            I want to give thanks to you {username} for...
          </Form.Label>
          {itemsThanks.map((item) => (
            <Form.Check
              label={item}
              value={item}
              onChange={this.handleInputChange}
              key={item}
            />
          ))}
          <br></br> <Form.Label>Personal Message</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            onChange={this.handleTextChange}
          />
          <button className="btn btn-primary" type="submit">
            Say thanks!
          </button>
        </Form>
      </div>
    );
  }
}

export default ExpressThanksForm;
