import React, { Component } from "react";
import { Form, Button, Col, Container } from "react-bootstrap";
import userService from "../../../../services/user-service";

class UserDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userlist: [],
    };
  }

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  saveAndContinue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };
  componentDidMount() {
    userService.getUserList().then(
      (response) => {
        const userlist = response.data.users;

        this.setState({
          userlist,
        });

        // console.log(this.state.userlist);
        // console.log(userlist);
      },
      (error) => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });
      }
    );
  }

  render() {
    return (
      <Container>
        <img
          src="https://publichelpdet.s3.us-west-2.amazonaws.com/help-color.png"
          alt="profile"
          className="profileImage-inter"
        />
        <Form>
          <Form.Row>
            <Form.Group as={Col} controlId="formchosenUser">
              <Form.Label className="label">
                <h3 className="center-text">
                  Help someone who might be struggling!
                </h3>
              </Form.Label>

              <Form.Control
                as="select"
                name="chosenUser"
                defaultValue={this.props.inputValues.chosenUser}
                onChange={this.props.handleChange}
              >
                {this.state.userlist.map((user, index) => (
                  <option value={user._id}>{user.username}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form.Row>

          <Button variant="primary" onClick={this.saveAndContinue}>
            Next
          </Button>
        </Form>
      </Container>
    );
  }
}

export default UserDetails;
