import React, { Component } from "react";
import AuthService from "../services/auth-service";
import UserService from "../services/user-service";
import PictureUpload from "./profile-features/picture-upload-component";
import { Button } from "react-bootstrap";
import { Arrow90degRight, Check, Circle } from "react-bootstrap-icons";

export default class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthService.getCurrentUser(),
      profileImg: "",
    };
  }

  componentDidMount() {}

  render() {
    return (
      <div className="container">
        {" "}
        <h2>
          <strong>
            {" "}
            Coding for Interdisciplinary Applications using Python <br></br>{" "}
          </strong>
        </h2>
      </div>
    );
  }
}
