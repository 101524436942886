import React, { Component } from "react";
import UserService from "../../services/user-service";
import { Modal, Button, ModalTitle } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import userService from "../../services/user-service";

export default class DeleteUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.id,
      isOpen: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }
  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  componentDidMount() {
    UserService.getUser(this.state.userDetails).then((response) => {
      let userId = response.data.data.user.id;
      //   console.log(response.data.data.user.userId);
      this.setState({ userId });
      //   console.log(this.state.roles);

      //console.log();
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    userService.deleteUser(this.state.userDetails).then(() => {
      window.location.reload();
    });
  }

  render() {
    return (
      <div>
        <Button className="btn-default" onClick={this.openModal}>
          Delete {this.state.username}
        </Button>
        <Modal show={this.state.isOpen} onHide={this.closeModal}>
          <ModalHeader>
            {" "}
            <ModalTitle>Are you sure you want to delete this user?</ModalTitle>
          </ModalHeader>
          <Modal.Body>
            {" "}
            <div className="row">
              <div className="col-sm-2">
                <Button
                  className="btn-success btn-lg"
                  onClick={this.closeModal}
                >
                  No
                </Button>
              </div>
              <div className="col-sm-2">
                <Button
                  className="btn-success btn-lg"
                  onClick={this.handleSubmit}
                >
                  Yes
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
