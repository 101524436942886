import React, { Component } from "react";
import ELPService from "../../services/elp-service";
import ProgBehavService from "../../services/progbehav-service";
import ELPIndivDetails from "./elp-activity-feed-individual-component";
import ELPProfileImg from "./elp-activity-feed-indiv-profile-img";
import moment from "moment";

export default class ELPActivityFeed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      progBehavTypeList: [],
    };
  }

  componentDidMount() {
    ELPService.getActivityFeed().then((response) => {
      const content = response.data.emotions;

      this.setState({
        data: content,
      });
    });

    ProgBehavService.getProgTypeList().then((response) => {
      const progBehavTypeList = response.data.progbehav;

      let dictProg = {};

      for (let i = 0; i < progBehavTypeList.length; i++) {
        dictProg[progBehavTypeList[i]._id] = progBehavTypeList[i].progbehavType;
      }
      this.setState({
        progBehavTypeList: dictProg,
      });
    });
  }

  newDateFormat(rawDate) {
    var stillUtc = moment.utc(rawDate).toDate();

    const fullTimestamp = moment(stillUtc)
      .local()
      .format("MMMM Do YYYY, h:mm:ss a");
    return fullTimestamp;
  }

  render() {
    const divStyle = {
      overflowY: "scroll",
      height: "700px",
    };

    return (
      <div>
        <div className="activity-feed" style={divStyle}>
          {this.state.data.map((item) => (
            <div className="feed-item">
              <span className="date">{this.newDateFormat(item.timestamp)}</span>
              <div className="text">
                <ELPProfileImg id={item.userId[0]._id}></ELPProfileImg>
                {item.userId.map((sub) => (
                  <span>{sub.username} </span>
                ))}
                is feeling{" "}
                {item.emotStatus.map((sub) => (
                  <span>{sub.emotion}</span>
                ))}
                ({this.state.progBehavTypeList[item.emotContext]}).
                <ELPIndivDetails data={item}></ELPIndivDetails>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
