import React, { Component } from "react";
import AuthService from "../services/auth-service";
import UserService from "../services/user-service";
import PictureUpload from "./profile-features/picture-upload-component";

export default class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthService.getCurrentUser(),
      profileImg: "",
    };
  }

  componentDidMount() {
    UserService.getUserProfileImg(this.state.currentUser.id).then(
      (response) => {
        this.setState({
          profileImg: response.data.url,
        });
      },
      (error) => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });
      }
    );
  }

  render() {
    const { currentUser } = this.state;

    return (
      <div className="container">
        <header className="jumbotron">
          <h3>
            <strong>{currentUser.username}</strong> Profile
          </h3>
        </header>
        <p>
          <strong>Current Profile Picture: </strong>{" "}
          <img
            src={this.state.profileImg}
            alt="profile"
            className="profileImage"
            onError={(e) => {
              e.currentTarget.src =
                "https://publichelpdet.s3.us-west-2.amazonaws.com/default-picture.png";
            }}
          />
          <hr></hr>
          <strong>Picture File: </strong> <PictureUpload></PictureUpload>
        </p>
        <p>
          <strong>Id:</strong> {currentUser.id}
        </p>
        <p>
          <strong>Email:</strong> {currentUser.email}
        </p>
        <strong>Authorities:</strong>
        <ul>
          {currentUser.roles &&
            currentUser.roles.map((role, index) => <li key={index}>{role}</li>)}
        </ul>
      </div>
    );
  }
}
