import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import AuthService from "../../../services/auth-service";
import ELPService from "../../../services/elp-service";
import ProgBehavTypeService from "../../../services/progbehav-service";
import { Form } from "react-bootstrap";
import ELPUserProfile from "../../features/elp-activity-feed-indiv-profile-img";

// import EmpathyNudgeForm from "./empathy-nudge-stepper/empathy-nudge-stepper";
import InterServices from "../../../services/intervention-service";

export default class EmpathyNudge extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthService.getCurrentUser(),
      content: this.props.data,
      isOpen: false,
      imgState: "http://localhost:8080/public/UserProfiles/",
      interImg: "http://localhost:8080/public/Interventions/",
      emotStatus: "",
      emotionlist: [],
      programTypeList: [],
    };

    this.changeEmotion = this.changeEmotion.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInterChange = this.handleInterChange.bind(this);
  }

  // openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  isPositiveOrNegative(unFormated) {
    var dict = {};

    for (let i = 0; i < this.state.emotionlist.length; i++) {
      // //console.log(this.state.emotionlist[i].emotion);
      // //console.log(this.state.emotionlist[i].type);

      dict[this.state.emotionlist[i]._id] = this.state.emotionlist[i].type;
    }

    // //console.log(dict);

    return dict;
  }

  componentDidMount() {
    //InterServices.updateToRead(this.state.content._id);
    ELPService.getEmotionList().then(
      (response) => {
        const emotionlist = response.data.emotion;

        this.setState({
          emotionlist,
        });

        // //console.log(this.state.emotionlist);
        //console.log("This is the list");

        this.setState({
          emotionPosNeg: this.isPositiveOrNegative(this.state.emotionlist),
        });

        //console.log(this.state.emotionPosNeg);
      },
      (error) => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });
      }
    );

    ProgBehavTypeService.getProgTypeList().then(
      (response) => {
        const programTypeList = response.data.progbehav;

        let buildProgList = {};

        //console.log(programTypeList);

        for (let i = 0; i < programTypeList.length; i++) {
          //console.log(programTypeList[i]._id);
          //console.log(programTypeList[i].progbehavType);

          buildProgList[programTypeList[i]._id] =
            programTypeList[i].progbehavType;
        }

        //console.log(buildProgList);

        this.setState({
          programTypeList: buildProgList,
        });
      },
      (error) => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });
      }
    );
  }
  handleInterChange(e) {
    // const target = event.target;
    // var message = target.value;
    e.preventDefault();

    this.setState({ isOpen: true });
    InterServices.updateToRead(this.state.content._id);
  }
  changeEmotion(e) {
    //console.log(this.state);

    this.setState({
      emotStatus: e.target.value,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    //console.log(this.state.emotStatus);
    //console.log(this.state.content.userIdfrom[0].currentEmotion);

    if (
      this.state.emotStatus === this.state.content.userIdfrom[0].currentEmotion
    ) {
      //console.log("Successfully Empathized");

      InterServices.generateIntervention(
        this.state.currentUser.id,
        this.state.content.userIdfrom[0]._id,
        "6434e07536a83600143f9026",
        this.state.programTypeList[
          this.state.content.userIdfrom[0].lastProgBehavType
        ]
      ).then(() => {
        window.location.reload();
      });
    } else {
      //console.log("Did not empathize");
      window.location.reload();
    }
  }

  render() {
    let username = this.state.content.userIdfrom[0].username;
    let profileImg = this.state.content.userIdfrom[0].profileImage;
    let progbehav = this.state.content.userIdfrom[0].lastProgBehavType;
    let progbehavName = this.state.programTypeList[progbehav];

    return (
      <div>
        {" "}
        {/* <img
          src={this.state.imgState + profileImg}
          alt="profile"
          className="profileImage-tiny"
        />{" "} */}
        <ELPUserProfile
          id={this.state.content.userIdfrom[0]._id}
        ></ELPUserProfile>
        <Button className="btn-info" onClick={this.handleInterChange}>
          Empathize with {username}
        </Button>
        {/* This is where you will change up what button and intervention will
        show! */}
        <Modal show={this.state.isOpen} onHide={this.closeModal}>
          <Modal.Header>
            <Modal.Title>
              {" "}
              <img
                src="https://publichelpdet.s3.us-west-2.amazonaws.com/help.png"
                alt="profile"
                className="profileImage-tiny"
              />
              <ELPUserProfile
                id={this.state.content.userIdfrom[0]._id}
              ></ELPUserProfile>
              <span className="inter-text"> Empathize with {username} </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <EmpathyNudgeForm data={this.state.content}></EmpathyNudgeForm> */}

            <h3>
              {username} experienced {progbehavName}. How is {username} feeling?
            </h3>
            <Form
              onSubmit={this.handleSubmit}
              ref={(c) => {
                this.form = c;
              }}
            >
              <ul>
                {this.state.emotionlist.map((item) => (
                  <li className="side-by-side">
                    <input
                      type="radio"
                      name="emotion"
                      value={item._id}
                      id={item._id + "-label"}
                      onChange={this.changeEmotion}
                    ></input>
                    <label for={item._id + "-label"}>
                      <img
                        src={
                          "https://publichelpdet.s3.us-west-2.amazonaws.com/" +
                          item.image
                        }
                        height="50"
                        width="50"
                      />{" "}
                      {item.emotion}
                    </label>
                  </li>
                ))}
              </ul>

              <input
                className="btn btn-primary btn-sm"
                type="submit"
                value="Submit"
              />
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
