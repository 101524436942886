import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import ELPForm from "./elp-self-report-form";
import InterventionService from "../../services/intervention-service";
// import { QuestionCircleFill } from "react-bootstrap-icons";

class EmotionSelfReportInter extends Component {
  state = {
    content: this.props.data,
    isOpen: false,
  };

  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  componentDidMount() {}

  render() {
    return (
      <>
        <Button
          className="btn btn-primary"
          variant="primary"
          onClick={this.openModal}
        >
          How do you feel?
        </Button>
        <Modal show={this.state.isOpen} onHide={this.closeModal}>
          <Modal.Header>
            <Modal.Title> How do you feel?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ELPForm></ELPForm>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default EmotionSelfReportInter;
