import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { EnvelopeFill, KanbanFill } from "react-bootstrap-icons";
import { Form } from "react-bootstrap";
import AuthService from "./../../services/auth-service";
import MessageService from "./../../services/message-service";
import InterventionService from "../../services/intervention-service";
import moment from "moment";

// import HelpGivingForm from "./help-giving-intervention-stepper/stepper-help-give";

export default class NewMessage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthService.getCurrentUser(),
      content: this.props.connect,
      isOpen: false,
      imgState: "http://localhost:8080/public/UserProfiles/",
      interImg: "http://localhost:8080/public/Interventions/",
      users: [],
      suggestions: [],
      text: "",
      subject: "",
      recipient: "",
      message: "",
      userData: [],
      sendTo: "",
    };
    // this.handleRecipientChange = this.handleRecipientChange.bind(this);
    this.handleSubjectChange = this.handleSubjectChange.bind(this);
    this.handleMessageChange = this.handleMessageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    // this.onTextChanged = this.onTextChanged.bind(this);
  }

  newDateFormat(rawDate) {
    var stillUtc = moment.utc(rawDate).toDate();

    const fullTimestamp = moment(stillUtc)
      .local()
      .format("MMMM Do YYYY, h:mm:ss a");
    return fullTimestamp;
  }

  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  handleSubjectChange(event) {
    const target = event.target;
    var subject = target.value;

    // this.state.subject = value;
    this.setState({ subject });
  }

  handleMessageChange(event) {
    const target = event.target;
    var message = target.value;
    this.setState({ message });
  }

  componentDidMount() {}

  handleSubmit(e) {
    e.preventDefault();

    MessageService.newMessage(
      this.state.content.userId[0]._id,
      this.state.currentUser.id,
      this.state.message,
      this.state.subject
    );

    InterventionService.generateReflection(
      this.state.currentUser.id,
      this.state.content.userId[0]._id,
      "6434dfd436a83600143f900c",
      true
    );

    this.setState({ isOpen: false });

    window.location.reload();

    // window.location.reload();
  }

  render() {
    return (
      <div>
        <Button className="btn-sm" variant="success" onClick={this.openModal}>
          <EnvelopeFill></EnvelopeFill> New hELP Message to{" "}
          {this.state.content.userId[0].username}
        </Button>
        <Modal show={this.state.isOpen} onHide={this.closeModal}>
          <Modal.Header>
            <Modal.Title>
              <EnvelopeFill></EnvelopeFill> New Message
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={this.handleSubmit}>
              <Form.Label>
                Recipient: {this.state.content.userId[0].username}{" "}
              </Form.Label>

              <br></br>
              <Form.Label>Subject Line: </Form.Label>

              <Form.Control
                as="textarea"
                rows={1}
                onChange={this.handleSubjectChange}
                placeholder="Let's connect!"
              />
              <Form.Label>Message: </Form.Label>

              <Form.Control
                as="textarea"
                rows={3}
                onChange={this.handleMessageChange}
              />

              {/* <button className="btn btn-primary" type="submit"> */}
              <button className="btn btn-primary" onClick={this.handleSubmit}>
                Send
              </button>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
