import React, { Component } from "react";
import { Tabs, Tab, Button } from "react-bootstrap";
import AuthService from "./../services/auth-service";
import NotificationService from "./../services/notification-service";
import HelpGivingIntervention from "./../components/intervention-features/help-giving-interventions/help-giving-intervention";
import HelpGivingInterventionB from "./../components/intervention-features/help-giving-interventions/help-giving-b-intervention";
import HelpGivingInterventionC from "./../components/intervention-features/help-giving-interventions/help-giving-c-intervention";
import HelpSeekingIntervention from "./intervention-features/ask-help-interventions/help-seeking-intervention";
import HelpSeekingInterventionB from "./intervention-features/ask-help-interventions/help-seeking-intervention-b";
import GiveThanks from "./intervention-features/gratitude-interventions/express-thanks";
import ReflectionIntervention from "./intervention-features/reflection-interventions/reflection";
import ActionA from "./intervention-features/actions-interventions/action-a";
import RequestDetails from "./../components/intervention-features/history-request-details";
import ReceiveThanks from "./intervention-features/gratitude-interventions/receive-thanks";
import ExpressThanksB from "./intervention-features/gratitude-interventions/express-thanks-b";
import EmpathyNudge from "./intervention-features/empathy-nudges-interventions/empathy-nudge-intervention";
import SelfReport from "./features/elp-self-report-intervention-component";
import HelpGivingInterventionEmpathy from "./../components/intervention-features/help-giving-interventions/help-giving-empathy-intervention";
import moment from "moment";

export default class RequestList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthService.getCurrentUser(),
      content: [],
      histContent: [],
      histHelpSeeking: [],
      histHelpGiving: [],
      reqHelpGiving: [],
      reqHelpSeeking: [],
    };
  }

  componentDidMount() {
    NotificationService.getUserRequests(this.state.currentUser.id).then(
      (response) => {
        this.setState({
          content: response.data.data.intervention,
        });
      },
      (error) => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });
      }
    );

    NotificationService.getUserRequests_HelpGiving(
      this.state.currentUser.id
    ).then(
      (response) => {
        this.setState({
          reqHelpGiving: response.data.data.intervention,
        });
      },
      (error) => {
        this.setState({
          reqHelpGiving:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });
      }
    );

    NotificationService.getUserRequests_HelpSeeking(
      this.state.currentUser.id
    ).then(
      (response) => {
        this.setState({
          reqHelpSeeking: response.data.data.intervention,
        });
      },
      (error) => {
        this.setState({
          reqHelpSeeking:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });
      }
    );

    NotificationService.getUserRequestHistory_HelpGiving(
      this.state.currentUser.id
    ).then(
      (response) => {
        this.setState({
          histHelpGiving: response.data.data.intervention,
        });
      },
      (error) => {
        this.setState({
          histHelpGiving:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });
      }
    );

    NotificationService.getUserRequestHistory_HelpSeeking(
      this.state.currentUser.id
    ).then(
      (response) => {
        this.setState({
          histHelpSeeking: response.data.data.intervention,
        });
      },
      (error) => {
        this.setState({
          histHelpSeeking:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });
      }
    );

    NotificationService.getUserRequestHistory(this.state.currentUser.id).then(
      (response) => {
        this.setState({
          histContent: response.data.data.intervention,
        });
      },
      (error) => {
        this.setState({
          histContent:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });
      }
    );
  }

  userHistoryPhrase(intervention) {
    let phrase = "";
    if (intervention === "give-help-a") {
      phrase = "You offered to help ";
    } else if (intervention === "give-help-b") {
      phrase = "You reached out to give help to";
    } else if (intervention === "ask-help-a") {
      phrase = "You reached out for help from ";
    } else if (intervention === "ask-help-b") {
      phrase = "You asked someone for help!";
    } else if (intervention === "reflection") {
      phrase = "You completed a reflection about your interaction with ";
    } else if (intervention === "give-thanks-a") {
      phrase = "You gave thanks to ";
    } else if (intervention === "give-thanks-b") {
      phrase = "You gave thanks to ";
    } else if (intervention === "action-a") {
      phrase = "You followed up to connect with ";
    } else if (intervention === "receive-thanks") {
      phrase = "You received thanks from ";
    } else if (intervention === "help-giving-c") {
      phrase = "You helped someone! ";
    } else if (intervention === "self-report") {
      phrase = "You reported an emotion recently!";
    } else {
      phrase = "null";
    }
    return phrase;
  }

  userRequestPhrase(intervention, username, data) {
    let phrase = "";
    if (intervention === "give-help-a") {
      phrase = username + " may need help!";
    } else if (intervention === "give-help-b") {
      phrase = "You can help " + username + "!";
    } else if (intervention === "ask-help-a") {
      phrase = "Ask " + username + " for help!";
    } else if (intervention === "ask-help-b") {
      phrase = "Ask someone for help!";
    } else if (intervention === "reflection") {
      let timeNowRaw = new Date(Date.now() - 86400 * 1000).toISOString();
      // let timeIntervention = moment(data.timestamp);
      let timeInterventionRaw = moment(data.timestamp);
      let timeNow = moment(timeNowRaw);
      // let timeSevenDays = timeIntervention.add(7, "days");

      let diffDays = timeNow.diff(timeInterventionRaw, "days");

      if (diffDays >= 7) {
        phrase = "Reflect on your recent interaction with " + username + "!";
      } else {
        phrase =
          "Reflect soon on your recent interaction with " + username + "!";
      }
    } else if (intervention === "give-thanks-a") {
      phrase = username + " says thanks! Say thank you.";
    } else if (intervention === "give-thanks-b") {
      phrase = "Say thank you to " + username + " !";
    } else if (intervention === "action-a") {
      phrase = username + " wants to connect! ";
    } else if (intervention === "receive-thanks") {
      phrase = username + " said thanks! ";
    } else if (intervention === "help-giving-c") {
      phrase = "Help someone who is struggling!";
    } else if (intervention === "self-report") {
      phrase = "You experienced the same error multiple times. Let's check in!";
    } else if (intervention === "empathy-nudge") {
      phrase = "Empathize with " + username;
    } else if (intervention === "help-giving-empathy") {
      phrase =
        "You empathized with " + username + "'s struggles. You can help!";
    } else {
      phrase = "null";
    }
    return phrase;
  }

  deliverInterventionComponent(intervention, data) {
    if (intervention === "give-help-a") {
      return <HelpGivingIntervention data={data}></HelpGivingIntervention>;
    } else if (intervention === "ask-help-a") {
      return <HelpSeekingIntervention data={data}></HelpSeekingIntervention>;
    } else if (intervention === "ask-help-b") {
      return <HelpSeekingInterventionB data={data}></HelpSeekingInterventionB>;
    } else if (intervention === "reflection") {
      //console.log("Inside of reflection");

      // let timeOfInterRef = data.timestamp.split("-");

      // let timeOfDayInterRef = timeOfInterRef[2].split("T")[0];

      // timeOfInterRef[2] = timeOfDayInterRef;

      // //console.log(timeOfInterRef);
      //console.log();

      // let timeSevenDays = new Date().getTime() + 7 * 24 * 60 * 60 * 1000;

      let timeNowRaw = new Date(Date.now() - 86400 * 1000).toISOString();
      // let timeIntervention = moment(data.timestamp);
      let timeInterventionRaw = moment(data.timestamp);
      let timeNow = moment(timeNowRaw);
      // let timeSevenDays = timeIntervention.add(7, "days");

      let diffDays = timeNow.diff(timeInterventionRaw, "days");

      //console.log(diffDays);

      if (diffDays >= 7) {
        return <ReflectionIntervention data={data}></ReflectionIntervention>;
      }
      // //console.log(timeNow);
      // //console.log(timeOfInterRef);
    } else if (intervention === "give-thanks-a") {
      return <GiveThanks data={data}></GiveThanks>;
    } else if (intervention === "give-thanks-b") {
      return <ExpressThanksB data={data}></ExpressThanksB>;
    } else if (intervention === "action-a") {
      return <ActionA data={data}></ActionA>;
    } else if (intervention === "give-help-b") {
      return <HelpGivingInterventionB data={data}></HelpGivingInterventionB>;
    } else if (intervention === "receive-thanks") {
      return <ReceiveThanks data={data}></ReceiveThanks>;
    } else if (intervention === "help-giving-c") {
      return <HelpGivingInterventionC data={data}></HelpGivingInterventionC>;
    } else if (intervention === "self-report") {
      return <SelfReport data={data}></SelfReport>;
    } else if (intervention === "empathy-nudge") {
      return <EmpathyNudge data={data}></EmpathyNudge>;
    } else if (intervention === "help-giving-empathy") {
      return (
        <HelpGivingInterventionEmpathy
          data={data}
        ></HelpGivingInterventionEmpathy>
      );
    } else {
      return "No intervention found! ";
    }
  }

  newDateFormat(rawDate) {
    const formated_Date = rawDate;
    const date = new Date(formated_Date); // formated_Date - SDK returned date
    let hours = date.getHours();

    const suffix = hours >= 12 ? "pm" : "am";

    hours = hours > 12 ? hours - 12 : hours;

    const fullTimestamp =
      date.getMonth() +
      1 +
      "/" +
      date.getDate() +
      "/" +
      date.getFullYear() +
      " " +
      hours +
      ":" +
      date.getMinutes() +
      " " +
      suffix;

    return fullTimestamp;
  }

  isReadMarker(read) {
    let readMessage = "";
    if (read === true) {
      readMessage = "";
    } else {
      readMessage = "New!";
    }
    return readMessage;
  }

  render() {
    let username = "";
    //  //console.log("Here");
    ////console.log(this.state.histHelpGiving);

    return (
      <div className="container">
        <h1>Requests</h1>
        <div className="row">
          <Tabs
            defaultActiveKey="new-requests"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="new-requests" title="Requests">
              <h5>View requests from peers who...</h5>
              {/* <Button variant="primary">Need Your Help</Button>{" "}
              <Button variant="primary">Can Help You</Button>{" "}
              <Button variant="primary">All Requests</Button> <hr></hr> */}
              <Tabs
                defaultActiveKey="all-requests"
                id="uncontrolled-tab-example-2"
                className="mb-3 remove-tab"
              >
                <Tab
                  eventKey="need-help"
                  tabClassName="sub-tab"
                  title="Need Your Help"
                >
                  {" "}
                  <div className="row">
                    {/* <div className="col-sm-4"> */}
                    {this.state.reqHelpGiving.map((item) => (
                      <div className="row">
                        <div className="">
                          {this.newDateFormat(item.timestamp)}{" "}
                          <span className="new-marker">
                            {this.isReadMarker(item.read)}
                          </span>
                        </div>
                        <div className=" request-item">
                          {item.InterventionType.map((cosub) => (
                            <span>
                              {item.userIdfrom.map((sub) => (
                                <span className="hidden-section">
                                  {(username = sub.username)}
                                </span>
                              ))}
                              {this.userRequestPhrase(
                                cosub.interventionType,
                                username,
                                item
                              )}

                              {this.deliverInterventionComponent(
                                cosub.interventionType,
                                item
                              )}
                            </span>
                          ))}
                        </div>
                      </div>
                    ))}
                    {/* </div> */}
                  </div>{" "}
                </Tab>

                <Tab
                  eventKey="can-help"
                  tabClassName="sub-tab"
                  title="Can Help You"
                >
                  <div className="row">
                    {/* <div className="col-sm-4"> */}
                    {this.state.reqHelpSeeking.map((item) => (
                      <div className="row">
                        <div className="">
                          {this.newDateFormat(item.timestamp)}{" "}
                          <span className="new-marker">
                            {this.isReadMarker(item.read)}
                          </span>
                        </div>
                        <div className="request-item">
                          {item.InterventionType.map((cosub) => (
                            <span>
                              {item.userIdfrom.map((sub) => (
                                <span className="hidden-section">
                                  {(username = sub.username)}
                                </span>
                              ))}
                              {this.userRequestPhrase(
                                cosub.interventionType,
                                username,
                                item
                              )}

                              {this.deliverInterventionComponent(
                                cosub.interventionType,
                                item
                              )}
                            </span>
                          ))}
                        </div>
                      </div>
                    ))}
                    {/* </div> */}
                  </div>{" "}
                </Tab>

                <Tab
                  eventKey="all-requests"
                  tabClassName="sub-tab"
                  title="All Requests"
                >
                  <div className="row">
                    {/* <div className="col-sm-4"> */}
                    {this.state.content.map((item) => (
                      <div className="row">
                        <div className="">
                          {this.newDateFormat(item.timestamp)}{" "}
                          <span className="new-marker">
                            {this.isReadMarker(item.read)}
                          </span>
                        </div>
                        <div className=" request-item">
                          {item.InterventionType.map((cosub) => (
                            <span>
                              {item.userIdfrom.map((sub) => (
                                <span className="hidden-section">
                                  {(username = sub.username)}
                                </span>
                              ))}
                              {this.userRequestPhrase(
                                cosub.interventionType,
                                username,
                                item
                              )}

                              {this.deliverInterventionComponent(
                                cosub.interventionType,
                                item
                              )}
                            </span>
                          ))}
                        </div>
                      </div>
                    ))}
                    {/* </div> */}
                  </div>{" "}
                </Tab>
              </Tabs>
            </Tab>
            <Tab eventKey="sent" title="Sent Requests">
              <h5>View sent requests where you are...</h5>

              <Tabs
                defaultActiveKey="all-requests-sent"
                id="uncontrolled-tab-example-2"
                className="mb-3 remove-tab"
              >
                <Tab
                  eventKey="asking-for-help"
                  tabClassName="sub-tab"
                  title="Asking for Help"
                >
                  <div className="row">
                    {this.state.histHelpSeeking.map((item) => (
                      <div className="row">
                        <div className="">
                          {this.newDateFormat(item.timestamp)}
                        </div>
                        <div className=" request-item">
                          {item.InterventionType.map((sub) => (
                            <span>
                              {this.userHistoryPhrase(sub.interventionType)}{" "}
                            </span>
                          ))}
                          {item.userIdTo.map((sub) => (
                            <span>{sub.username}</span>
                          ))}
                          {"."}
                          <br></br>
                          <RequestDetails data={item}></RequestDetails>
                        </div>
                      </div>
                    ))}
                  </div>{" "}
                </Tab>

                <Tab
                  eventKey="giving-help"
                  tabClassName="sub-tab"
                  title="Giving Help"
                >
                  <div className="row">
                    {this.state.histHelpGiving.map((item) => (
                      <div className="row">
                        <div className="">
                          {this.newDateFormat(item.timestamp)}
                        </div>
                        <div className=" request-item">
                          {item.InterventionType.map((sub) => (
                            <span>
                              {this.userHistoryPhrase(sub.interventionType)}{" "}
                            </span>
                          ))}
                          {item.userIdTo.map((sub) => (
                            <span>{sub.username}</span>
                          ))}
                          {"."}
                          <br></br>
                          <RequestDetails data={item}></RequestDetails>
                        </div>
                      </div>
                    ))}
                  </div>{" "}
                </Tab>

                <Tab
                  eventKey="all-requests-sent"
                  tabClassName="sub-tab"
                  title="All Sent Requests"
                >
                  {" "}
                  <div className="row">
                    {this.state.histContent.map((item) => (
                      <div className="row">
                        <div className="">
                          {this.newDateFormat(item.timestamp)}
                        </div>
                        <div className=" request-item">
                          {item.InterventionType.map((sub) => (
                            <span>
                              {this.userHistoryPhrase(sub.interventionType)}{" "}
                            </span>
                          ))}
                          {item.userIdTo.map((sub) => (
                            <span>{sub.username}</span>
                          ))}
                          {"."}
                          <br></br>
                          <RequestDetails data={item}></RequestDetails>
                        </div>
                      </div>
                    ))}
                  </div>
                </Tab>
              </Tabs>
            </Tab>
          </Tabs>
        </div>
      </div>
    );
  }
}
