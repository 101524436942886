import React, { Component } from "react";
import UserDetails from "./userdetails";
import RequestDetails from "./requests";
import Confirmation from "./confirmation";

class MultiStepForm extends Component {
  state = {
    step: 1,
    internal: "",
    im: "",
    email: "",
    social: "",
    content: this.props.data,
  };

  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });
  };

  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { step, internal, im, email, social } = this.state;
    const inputValues = {
      internal,
      im,
      email,
      social,
    };
    switch (step) {
      case 1:
        return (
          <UserDetails
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            inputValues={inputValues}
            data={this.state.content}
          ></UserDetails>
        );
      case 2:
        return (
          <RequestDetails
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            inputValues={inputValues}
            data={this.state.content}
          ></RequestDetails>
        );
      case 3:
        return (
          <Confirmation
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            inputValues={inputValues}
            data={this.state.content}
          ></Confirmation>
        );
    }
  }
}

export default MultiStepForm;
