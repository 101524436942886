import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import AuthService from "./../../services/auth-service";
import NewInterConnect from "./../messages/new-connection-af-message-component";
import ProgBehavService from "../../services/progbehav-service";
import UserService from "./../../services/user-service";

export default class EmotionDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthService.getCurrentUser(),
      content: this.props.id,
      progBehavTypeList: [],
      isOpen: false,
      profileImg: "",
    };
  }

  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  componentDidMount() {
    UserService.getUserProfileImg(this.state.content).then(
      (response) => {
        //  console.log(response.data.url);
        this.setState({
          profileImg: response.data.url,
        });
      },
      (error) => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });
      }
    );

    // ProgBehavService.getProgTypeList().then((response) => {
    //   const progBehavTypeList = response.data.progbehav;
    //   let dictProg = {};
    //   for (let i = 0; i < progBehavTypeList.length; i++) {
    //     dictProg[progBehavTypeList[i]._id] = progBehavTypeList[i].progbehavType;
    //   }
    //   // console.log(dictProg);
    //   this.setState({
    //     progBehavTypeList: dictProg,
    //   });
    // });
  }

  render() {
    // let username = this.state.content.userId[0].username;
    // let emotion = this.state.content.emotStatus[0].emotion;
    // let details = this.state.content.details;
    // let profileImg = this.state.content.userId[0].profileImage;
    // let emotionContext = this.state.content.emotContext;

    // // console.log("test: ");
    // // console.log();

    return (
      <img
        src={this.state.profileImg}
        alt="profile"
        className="profileImage-tiny"
        onError={(e) => {
          e.currentTarget.src =
            "https://publichelpdet.s3.us-west-2.amazonaws.com/default-picture.png";
        }}
      />
      // <img
      //   src="http://breakfastcavies.com/image/pancake.png"
      //   alt="profile"
      //   className="profileImage-tiny"
      // />
    );
  }
}
