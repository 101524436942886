import React, { Component } from "react";
import UserService from "../services/user-service";
import ChangeRole from "../components/user-admin/changeRole-component";
import DeleteUser from "./user-admin/delete-user-component";

export default class UserList extends Component {
  constructor(props) {
    super(props);
    // constructor(props) {
    //   super(props);

    this.state = {
      items: [],
      value: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit(event) {
    alert("Changed to this user role:  " + this.state.value);
    event.preventDefault();
  }
  componentDidMount() {
    UserService.getUserList().then(
      (response) => {
        const items = [];

        for (let i = 0; i < response.data.users.length; i++) {
          const item = [];

          item.push(response.data.users[i]._id);
          item.push(response.data.users[i].username);
          item.push(response.data.users[i].email);

          items.push(item);
        }

        this.setState({ items });
      },
      (error) => {
        this.setState({
          content:
            (error.response && error.response.data) ||
            error.message ||
            error.toString(),
        });
      }
    );
  }
  render() {
    return (
      <div className="container">
        <div class="row">
          <div class="col-sm-2">
            <h3>Username</h3>{" "}
          </div>
          <div class="col-sm-2">
            <h3>Email</h3>{" "}
          </div>
          <div class="col-sm-3">
            <h3>Role</h3>
          </div>
          <div class="col-sm-2">
            <h3>Delete User</h3>
          </div>
        </div>
        {this.state.items.map((item) => (
          <div class="row">
            <div class="col-sm-2">{item[1]}</div>
            <div class="col-sm-2">{item[2]}</div>
            <div class="col-sm-3">
              <ChangeRole data={item[0]}></ChangeRole>
              {/* <form onSubmit={this.handleSubmit}>
                <label>
                  <select value={item[1]} onChange={this.handleChange}>
                    <option value="Moderator">Moderator</option>
                    <option value="Student">Student</option>
                    <option value="TA">Teaching Assistant</option>
                    <option value="User">User</option>
                  </select>
                </label>
                <input type="submit" value="Change Role" />
              </form> */}
            </div>
            <div class="col-sm-2">
              {" "}
              <DeleteUser data={item[0]} username={item[1]}></DeleteUser>
              <br></br>
              <br></br>
            </div>
          </div>
        ))}
      </div>
    );
  }
}
