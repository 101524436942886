import React, { Component } from "react";
import { Form, Button, Col, Container } from "react-bootstrap";
import userService from "../../../../services/user-service";
import ELPUserProfile from "../../../features/elp-activity-feed-indiv-profile-img-md";

class UserDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userlist: [],
      content: this.props.data,
      imgState: "http://localhost:8080/public/UserProfiles/",
    };
  }

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  saveAndContinue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };
  componentDidMount() {
    userService.getUserList().then(
      (response) => {
        const userlist = response.data.users;

        this.setState({
          userlist,
        });

        // console.log(this.state.userlist);
        //  console.log(userlist);
      },
      (error) => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });
      }
    );
  }

  render() {
    let username = this.state.content.userIdfrom[0].username;
    let profileImg = this.state.content.userIdfrom[0].profileImage;

    return (
      <Container>
        {/* <img
          src={this.state.imgState + profileImg}
          alt="profile"
          className="profileImage-med"
        />{" "} */}
        <ELPUserProfile
          id={this.state.content.userIdfrom[0]._id}
        ></ELPUserProfile>
        <Form>
          <p>
            <h3 className="center-text">
              {" "}
              You can help {username}! <br></br>
              {username} reached out to you for help. <br></br> Let them know
              how you can help!{" "}
            </h3>
            <strong>They said, </strong>
            <br></br>
            <br></br>
            {this.state.content.details.map((sub) => (
              <li>{sub} </li>
            ))}
          </p>

          <Button variant="primary" onClick={this.saveAndContinue}>
            Next
          </Button>
        </Form>
      </Container>
    );
  }
}

export default UserDetails;
