import React, { Component } from "react";
import { Form, Button, Col, Container } from "react-bootstrap";

class AddressDetails extends Component {
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  saveAndContinue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  render() {
    const itemsSeek = [
      "I want help understanding code.",
      "I want to collaborate/study with you.",
      "I want help/tutoring on course material.",
      "I want emotional support/someone to talk to.",
    ];

    return (
      <Container>
        <Form>
          <Form.Group controlId="formAddress">
            <Form.Label>Let them know how they can help! </Form.Label>
            <p>Check all that apply.</p>

            <Form.Check
              label={itemsSeek[0]}
              value={itemsSeek[0]}
              name="understand"
              onChange={this.props.handleChange}
            />
            <Form.Check
              label={itemsSeek[1]}
              value={itemsSeek[1]}
              name="collaborate"
              onChange={this.props.handleChange}
            />

            <Form.Check
              label={itemsSeek[2]}
              value={itemsSeek[2]}
              name="tutoring"
              onChange={this.props.handleChange}
            />

            <Form.Check
              label={itemsSeek[3]}
              value={itemsSeek[3]}
              name="emotional"
              onChange={this.props.handleChange}
            />
          </Form.Group>
          <Button variant="secondary" onClick={this.back}>
            Back
          </Button>{" "}
          <Button variant="primary" onClick={this.saveAndContinue}>
            Next
          </Button>
        </Form>
      </Container>
    );
  }
}

export default AddressDetails;
